import React, { createContext, useState } from "react";

export const ProcessModalContext = createContext();

export const ProcessModalProvider = ({ children }) => {
  const [processOpenModalId, setProcessOpenModalId] = useState(null);
  const [successModalId, setSuccessModalId] = useState(null);

  const openProcessModal = (id) => {
    setProcessOpenModalId(id);
  };
  const closeProcessModal = () => {
    setProcessOpenModalId(null);
  };

  const openSuccessModal = (id) => {
    setSuccessModalId(id);
  };

  const closeSuccessModal = () => {
    setSuccessModalId(null);
  };
  // Open New search section function

  const [disabled, setDisabled] = useState(false);

  const handleOpenNewSearch = () => {
    const section = document.getElementById("Mobile_New_Search");
    if (section) {
      section.classList.toggle("open_search_new_number");
      setDisabled(!disabled);
    }
  };

  return (
    <ProcessModalContext.Provider
      value={{
        processOpenModalId,
        openProcessModal,
        closeProcessModal,
        successModalId,
        closeSuccessModal,
        openSuccessModal,
        handleOpenNewSearch,
        disabled,
      }}
    >
      {children}
    </ProcessModalContext.Provider>
  );
};
