import React from "react";
import { t } from "i18next";
import {
  accordionIcon,
  premiumToggleBar,
  possiblePhotosImg,
  nodataIcon,
} from "../../../assets/images";
import { Collapse } from "antd";
import UnlockReport from "../unlockReport/unlockReport";
import { renderValue, toCamelCase } from "../../../utils/commonUtils";
import SectionAction from "../sectionAction/sectionAction";
import NoInfoFound from "../noInfoFound/noInfoFound";

const WorkHistory = ({ isLocked, sectionName, reportDetail, number }) => {
  const { possible_jobs } = reportDetail?.phoneReport || {};
  const { data, status } = possible_jobs || {};

  const { phone_owner_info } = reportDetail?.phoneReport || {};
  const isData = phone_owner_info?.status === "found";
  return isLocked ? (
    <UnlockReport
      title={t("PR_PREMIUM_SECTION_TITLE_2")}
      image={possiblePhotosImg}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_WORK_HISTORY_MESSAGE", {
        number: 12,
      })}
      sectionName={sectionName}
      pricingSectionName={"possible_jobs"}
      id={"Work History"}
    />
  ) : !isLocked && status === "not_found" ? (
    <NoInfoFound sectionName={sectionName} id={toCamelCase("Work History")} />
  ) : (
    <div
      className="ds--unlock-jobs white-bg-wrap"
      id={toCamelCase("Work History")}
    >
      <div className="ds--unlock__title left-content main_title toggle_area">
        <h2>{t("PR_PREMIUM_SECTION_TITLE_2")}</h2>
        <SectionAction />
      </div>
      {status === "found" && data.length > 0 ? (
        <>
          <div className="ds--accordion-collapse">
            <Collapse
              items={[
                {
                  key: "1",
                  label: t("PR_LEARN_MORE"),
                  children: (
                    <p>
                      2{" "}
                      {isData && phone_owner_info.data.name
                        ? t("PR_WORK_HISTORY_TITLE_2", {
                            phone_owner_full_name: isData
                              ? renderValue(phone_owner_info.data.name)
                              : "USER",
                            "is/are": data.length > 1 ? "are" : "is",
                          })
                        : t("PR_WORK_HISTORY_TITLE_1", {
                            phone_number: isData
                              ? reportDetail?.phoneReport.phone_number_prefix +
                                reportDetail?.phoneReport.phone_number
                              : "USER",
                            "is/are": data.length > 1 ? "are" : "is",
                          })}
                    </p>
                  ),
                },
              ]}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <img
                  src={isActive ? accordionIcon : accordionIcon}
                  width="2em"
                  height="2em"
                  alt=""
                />
              )}
            />
          </div>
          <div className="ds--job-wrapper">
            {data.map((job, index) => (
              <div key={job._id} className="ds--job-box">
                <h3>
                  {" "}
                  {t("PR_JOB")} {index + 1}
                </h3>
                <p>
                  <b>{t("PR_COMPANY_NAME")}:</b>{" "}
                  {renderValue(job?.company_name)}
                </p>
                <p>
                  <b>{t("PR_JOB_TITLE")}:</b> {renderValue(job?.job_title)}
                </p>
                <p>
                  <b>{t("PR_INDUSTRY")}:</b> {renderValue(job?.industry)}
                </p>
                <p>
                  <b>{t("PR_START_DATE")}:</b> {renderValue(job?.start_date)}
                </p>
              </div>
            ))}
          </div>
        </>
      ) : (
        <p className="data_notfound">
          <img src={nodataIcon} alt="" /> {t("PR_RECORD_NOT_FOUND")}
        </p>
      )}
    </div>
  );
};

export default WorkHistory;
