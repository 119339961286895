import { t } from "i18next";
import { moreReportIcon } from "../../../../assets/images";

const AddMoreReport = ({ handleFlow, price }) => {
  return (
    <div className="wpb_report__wrapper">
      <div className="vc_icon__general">
        <img src={moreReportIcon} alt="" />
      </div>
      <div className="vc_content__general">
        <h2>{t("PR_UNLOCK_MORE_REPORT")}</h2>
        <p dangerouslySetInnerHTML={{ __html: t("PR_PURCHASE_ADDITIONAL") }}></p>
      </div>
      <div className="vc_button__general">
        <button className="vc_btn3-inline" onClick={handleFlow}>
          {t("PR_CANCELLATION_CONFIRM_BUTTON")}
        </button>
      </div>
      <div className="vc_info__general">
        <p>{t("PR_MEMBERSHIP_RENEW_2", { price: price })}</p>
      </div>
    </div>
  );
};

export default AddMoreReport;
