import React, { useState, useEffect } from "react";
import "./polyAnimation.scss";
import { t } from "i18next";

const PolyAnimation = () => {
  const [textIndex, setTextIndex] = useState(0);
  const texts = [
    t("PR_SOCIAL").toUpperCase(),
    t("PR_EMAILS").toUpperCase(),
    t("PR_PHONES").toUpperCase(),
    t("PR_ADDRESSES").toUpperCase(),
    `${t("PR_PREPARING_REPORT").toUpperCase()}...`,
    t("PR_COMPLETE").toUpperCase(),
  ];

  useEffect(() => {
    document.body.style.overflow = "hidden";
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => {
        if (prevIndex === texts.length - 2) {
          clearInterval(interval); // Stop interval when "Complete" is next
          document.body.style.overflow = "auto"; // Re-enable scrolling when navigating away
          return prevIndex + 1; // Move to "Complete"
        } else {
          return (prevIndex + 1) % texts.length;
        }
      });
    }, 2000);

    return () => {
      clearInterval(interval);
      document.body.style.overflow = "auto"; // Re-enable scrolling when component unmounts
    };
  }, []);

  return (
    <div className="main_root">
      <div className="classUp_mrg">
        <div className="polyanimation_wrapper">
          <div className="animation_title">
            <h2>{t("PHONE_REPORT_LOADING")}</h2>
            <p>{t("PR_ANIMATION_TEXT")}</p>
          </div>
          <div className="animation_inner_wrap">
            <div className="animation_spin1"></div>
            <div className="animation_spin2"></div>
            <div className="animation_spin3"></div>
            <div className="animation_spin4"></div>
            <div className="animation_spin5"></div>
            <div className="animation_text_wrap">{texts[textIndex]}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolyAnimation;
