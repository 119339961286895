import React, {useContext, useEffect, useState} from "react";
import PremiumPageComponent from "./premiumPage";
import io from "socket.io-client";
import {ProcessModalContext} from "../../context/processModalProvider";
import {connect, useSelector} from "react-redux";
import Loader from "../../components/common/loader/loader";
import {useSearchParams} from "react-router-dom";
import { unlockOneSection } from "../../redux/slice/unlockOneSectionSlice";
import { unlockAllSection } from "../../redux/slice/unlockAllSectionSlice";
import { createSubscription } from "../../redux/slice/createSubscriptionSlice";
import Notify from "../../../components/common/Notify/notify";
import { getPhoneReportDetails } from "../../redux/slice";
import { openReport } from "../../redux/slice/openReportSlice";
import { t } from "i18next";
import {connectSocket} from "../../socket";

const socket = connectSocket();

const PremiumPage = (props) => {
  const {
    callUnlockOneSection,
    callUnlockAllSection,
    callCreateSubscription,
    callGetPhoneReportDetails,
    callOpenReport
  } = props;
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const {phoneReport, addition_info} = props.phoneReportDetails || {};
  const isInProgress = phoneReport?.external_call_status === "in_progress";
  const userId = useSelector(
    (state) => state.authenticate?.authenticateData?.data?._id
  );
  const reportDetailLoading = useSelector(
    (state) => state.getPhoneReportDetails?.isLoading
  );
  const {
    closeProcessModal,
    openSuccessModal,
    closeSuccessModal,
    openProcessModal
  } = useContext(ProcessModalContext);

  const [infoModal, setInfoModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (addition_info?.status) {
      setInfoModal(true);
    }
  }, []);

  useEffect(() => {
    if (isInProgress) {
      console.log("Call Modal by checking check in detail", isInProgress);
      openProcessModal(phoneReport._id);
    }
    return () => {
      closeSuccessModal();
      closeProcessModal();
    };
  }, []);

  useEffect(() => {
    const handleDataReceived = async (data) => {
      console.log("socket call from premiumPage", data);
      if (data?.data?.type !== "monitor") {
        openProcessModal(data.data.phone_report_id);
        setTimeout(() => {
          closeProcessModal();
          openSuccessModal(data.data.phone_report_id);
        }, 1000);
      }
    };
    socket.on(userId, handleDataReceived);
    return () => {
      socket.off(userId, handleDataReceived);
    };
  }, [userId]);

  const handleViewReport = async () => {
    closeSuccessModal();
    await callGetPhoneReportDetails(phoneReport?._id);
  };

  useEffect(() => {
    callGetPhoneReportDetails(searchNumber?.id);
  }, [searchNumber?.id]);

  useEffect(() => {
    function onFooEvent(value) {
      console.log("Data found:", value);
    }
    socket.on("connect", () => {
      console.log(socket.id);
    });
    socket.on("disconnect", () => {
      console.log(socket.id);
    });
    socket.on(phoneReport?._id, onFooEvent);
    return () => {
      socket.off(phoneReport?._id, onFooEvent);
    };
  }, [phoneReport?._id]);

  const onInfoSubmit = async () => {
    try {
      const {apiName, apiPayload} =
        props?.phoneReportDetails?.addition_info?.payload;
      const apiActions = {
        unlock_one_section: callUnlockOneSection,
        unlock_all_section: callUnlockAllSection,
        subscription_unlock_full_report: callCreateSubscription,
        open_report: callOpenReport
      };
      const action = apiActions[apiName];
      if (action) {
        const res = await action(apiPayload);
        if (res.type.includes("fulfilled")) {
          if (res?.payload?.data?.eventObj?.events) {
            openProcessModal(phoneReport._id);
          } else {
            Notify("success", res.payload.message, "");
            await callGetPhoneReportDetails(phoneReport?._id);
          }
        } else {
          Notify("error", res.error.message, "");
        }
      }
    } catch (error) {
      Notify("error", error.message || t("PR_SOMETHING_WENT_WRONG"), "");
    }
  };

  return (
    <>
      {(reportDetailLoading || isLoading) && <Loader />}
      <PremiumPageComponent
        {...props}
        handleViewReport={handleViewReport}
        onInfoSubmit={onInfoSubmit}
        setInfoModal={setInfoModal}
        infoModal={infoModal}
        setIsLoading={setIsLoading}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticate: state.authenticate,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callUnlockOneSection: (data) => dispatch(unlockOneSection(data)),
    callUnlockAllSection: (data) => dispatch(unlockAllSection(data)),
    callCreateSubscription: (data) => dispatch(createSubscription(data)),
    callGetPhoneReportDetails: (data) => dispatch(getPhoneReportDetails(data)),
    callOpenReport: (data) => dispatch(openReport(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PremiumPage);
