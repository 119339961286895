import { t } from "i18next";
import {
  landlineIcon,
  pagerIcon,
  premiumIcon,
  reportTrackPhoneIcon,
  tollFreeIcon,
  verifyPaymentIcon,
  wireLessIcon,
  voIPIcon,
} from "../../../assets/images";
import "./reportHeader.scss";
import { useEffect, useState } from "react";

const ReportHeader = ({ number, phoneReport }) => {
  const { general_phone_info } = phoneReport || {};
  const { line_type } = general_phone_info?.data || {};

  const [phoneType, setPhoneType] = useState("");

  useEffect(() => {
    switch (line_type) {
      case "Landline":
        setPhoneType(
          <div className="wpl__reportBox__status metallic_blue">
            <img src={landlineIcon} alt="" />
            <span> {t("PR_PHONE_TYPE_LANDLINE")}</span>
          </div>
        );
        break;
      case "Wireless":
        setPhoneType(
          <div className="wpl__reportBox__status bondi_orange">
            <img src={wireLessIcon} alt="" />
            <span> {t("WIRELESS")}</span>
          </div>
        );
        break;
      case "VoIP":
        setPhoneType(
          <div className="wpl__reportBox__status bondi_green">
            <img src={voIPIcon} alt="" />
            <span> {t("PR_VOIP")}</span>
          </div>
        );
        break;
      case "Pager":
        setPhoneType(
          <div className="wpl__reportBox__status bondi_blue">
            <img src={pagerIcon} alt="" />
            <span> {t("PR_PAGER")}</span>
          </div>
        );
        break;
      case "Toll Free":
        setPhoneType(
          <div className="wpl__reportBox__status smashed_pumpkin">
            <img src={tollFreeIcon} alt="" />
            <span> {t("PR_TOLL_FREE")}</span>
          </div>
        );
        break;
      case "Premium":
        setPhoneType(
          <div className="wpl__reportBox__status rust">
            <img src={premiumIcon} alt="" />
            <span> {t("PR_PREMIUM")}</span>
          </div>
        );
        break;
      default:
        setPhoneType(
          <div className="wpl__reportBox__status">
            <img src={verifyPaymentIcon} alt="" />
            <span> {t("PR_STEP_3_MOBILE")}</span>
          </div>
        );
    }
  }, [line_type]);

  return (
    <div className="reversly__boxWrapper white-bg-wrap">
      <div className="wpl__reportBox_wrapper">
        <div className="wpl__reportBox__image">
          <img src={reportTrackPhoneIcon} alt="" />
        </div>
        <div className="wpl__reportBox__content">
          <div className="wpl__reportBox__title">
            <h2>{t("PR_PHONE_REPORT")}</h2>
            {phoneType}
          </div>
          <div className="wpl__reportBox__numberTrack">
            <p>
              <a>{number ? number : "+1 (819) 674-0000"}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReportHeader;
