import React, { useContext, useEffect, useRef, useState } from "react";
import "./phoneReportMenu.scss";
import { Link } from "react-router-dom";
import {
  dashboardIcon,
  fullReportsUnlockedIcon,
  fullReportUnlockedIcon,
  lockIcon,
  newHeaderFileIcon,
  newHeaderSearchIcon,
  newHeaderUnlockIcon,
  pdfIcon,
  researchIcon,
  unlockedPhonereportIcon,
} from "../../../assets/images";

import { PhoneReportPayModal } from "../../partials";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { subscriptionStatus } from "../../../redux/slice/subscriptionStatusSlice";
import Loader from "../loader/loader";
import Notify from "../../../../components/common/Notify/notify";
import MultiActionModal from "../../partials/Modals/multiActionModal";
import { pdfSubscriptionStatus } from "../../../redux/slice/pdfSubscriptionStatusSlice";
import { getPdfUrl } from "../../../redux/slice/getPdfUrlSlice";
import { useDownloadReport } from "../../../hooks/useDownloadReport";
import { Cookies } from "react-cookie";
import MonitorSwitch from "../../partials/noInfoFound/monitorSwitch";
import { Tooltip } from "antd";
import {CONSTANTS} from "../../../utils/constant";
import { ProcessModalContext } from "../../../context/processModalProvider";

const PhoneReportMenu = (props) => {
  const {
    // API and state
    callCheckSubscriptionStatus,
    phoneReportDetails,
    pricingTitleList
  } = props;
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const {t} = useTranslation();
  const {isPhoneReport, phoneReport} = phoneReportDetails || {};
  const {phone_number, phone_number_prefix} = phoneReport || {};
  const number = phone_number_prefix + " " + phone_number;
  const isFullReportUnlocked = phoneReport?.status === "unlocked";
  const {handleDownloadReport, PdfActionModal} = useDownloadReport();

  const [payModalOpen, setPayModalOpen] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState();
  const [mode, setMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [pricingData, setPricingData] = useState({});
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Hide the tooltip when the user scrolls
      if (isTooltipVisible) {
        setTooltipVisible(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isTooltipVisible]);
  useEffect(() => {
    if (pricingTitleList) {
      setPricingData({
        label_1:
          pricingTitleList?.sections?.["unlock_all_section"]?.amount || 9.95,
        label_2:
          pricingTitleList?.subscriptions?.[
            CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT
          ]?.amount || CONSTANTS.WRONG_PRICING.PREMIUM_REPORT,
        symbol:
          pricingTitleList?.sections?.["unlock_all_section"]?.symbol || "$"
      });
    }
  }, [pricingTitleList]);

  useEffect(() => {
    let height = document.getElementById("menu_item_btn").clientHeight;
    if (height > 60) {
      document
        .getElementById("menu_item_btn")
        .classList.add("menu_item_buttons");
    }
  }, []);

  const handleOpenPayModal = async (mode, data) => {
    setIsLoading(true);
    const res = await callCheckSubscriptionStatus(
      CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT
    );
    if (res.type === "subscriptionStatus/fulfilled") {
      const {subscription, usages, canceled} = res.payload.data || {};
      if (!subscription && canceled) {
        setActionModal(true);
        setModalType("renew_subscription");
      } else if (!subscription) {
        setSubscriptionData(data);
        setPayModalOpen(true);
        setMode(mode);
      } else if (subscription && usages) {
        setActionModal(true);
        setModalType("open_report");
      } else if (subscription && !usages) {
        setActionModal(true);
        setModalType("more_report_subscription");
      }
    } else {
      Notify("error", res.error.message, "");
    }
    setIsLoading(false);
  };
  // New Search
  const {handleOpenNewSearch, disabled} = useContext(ProcessModalContext);
  return (
    <>
      {isLoading && <Loader />}
      <MultiActionModal
        actionModal={actionModal}
        setActionModal={setActionModal}
        modalType={modalType}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        subscription={CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT}
      />
      <PhoneReportPayModal
        isOpen={payModalOpen}
        onClose={() => setPayModalOpen(false)}
        subscriptionData={subscriptionData}
        mode={mode}
        pricingData={pricingData}
        number={number}
      />
      {PdfActionModal}
      <div className="ds_full_reportMenu menu_item_wrap">
        <div className="menu_item_box_wrap">
          <div id="menu_item_btn" className="menu_item_btn">
            <p>
              <Tooltip
                placement="top"
                overlayClassName="menu__header_tooltip"
                title={
                  isFullReportUnlocked
                    ? t("PR_FULL_REPORT_ALREDY_UNLOCKED")
                    : t("PR_UNLOCK") + " " + t("PR_FULL_REPORT_HEADER")
                }
                visible={isTooltipVisible}
                onVisibleChange={(visible) => setTooltipVisible(visible)} // Update visibility state
              >
                <Link
                  to="javascript:void(0)"
                  data-title="Unlock Full Report"
                  className={`menu_button UnlockFullReport ${
                    isFullReportUnlocked && "disabled_unllock_report_btn"
                  }`}
                  onClick={() =>
                    !isFullReportUnlocked &&
                    handleOpenPayModal("full_report", {
                      title: t("PR_STEP_3_TITLE_1"),
                      description: "PR_UNLOCK_POPUP_DESCRIPTION",
                      label1: "PR_UNLOCK_POPUP_TEXT_1",
                      label2: "PR_UNLOCK_POPUP_TEXT_2",
                      reports: "PR_UNLOCK_POPUP_REPORTS"
                    })
                  }
                >
                  <img
                    src={
                      isFullReportUnlocked
                        ? fullReportsUnlockedIcon
                        : unlockedPhonereportIcon
                    }
                    alt=""
                  />{" "}
                  {isFullReportUnlocked ? (
                    <>
                      {t("PR_FULL_REPORT_HEADER")}
                      <span>{t("PR_UNLOCKED_HEADER")}</span>
                    </> // string convert
                  ) : (
                    <>
                      <span>{t("PR_UNLOCK")}</span> {t("PR_FULL_REPORT_HEADER")}
                    </>
                  )}
                </Link>
              </Tooltip>
            </p>
            <p>
              <Link
                to={"javascript:void(0)"}
                data-title="PDF"
                className="menu_button"
                onClick={() => handleDownloadReport(phoneReport?._id)}
              >
                <img src={newHeaderFileIcon} alt="" />
                <span className="pdf_download">
                  {t("PR_DOWNLOAD_HEADER")}{" "}
                </span>{" "}
                {t("PR_PDF_HEADER")}
              </Link>
            </p>
            <p>
              <MonitorSwitch from="reportMenu" />
            </p>
            {window.screen.width > 768 ? (
              <p>
                <Link
                  to={`/${lang}/dashboard`}
                  data-title="Search other Number"
                  className="menu_button"
                >
                  <img
                    src={newHeaderSearchIcon}
                    alt=""
                    width="21"
                    height="21"
                  />
                  {t("PR_NEW_SEARCH_HEADER")}
                </Link>
              </p>
            ) : (
              <p>
                <a
                  href="javascript:void(0)"
                  data-title="Search other Number"
                  className={`menu_button`}
                  onClick={handleOpenNewSearch}
                >
                  <img
                    src={newHeaderSearchIcon}
                    alt=""
                    width="21"
                    height="21"
                  />
                  {t("PR_NEW_SEARCH_HEADER")}
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    phoneReportDetails:
      state.getPhoneReportDetails?.getPhoneReportDetailsData?.data,
    pricingTitleList: state.pricingTitleList?.getPhoneReportDetailsData?.data
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callCheckSubscriptionStatus: (data) => dispatch(subscriptionStatus(data)),
    callPdfSubscriptionStatus: (data) => dispatch(pdfSubscriptionStatus(data)),
    callGetPdfUrl: (data) => dispatch(getPdfUrl(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneReportMenu);
