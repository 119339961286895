import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";

export const getPhoneReportDetails = createAsyncThunk(
  "getPhoneReportDetails",
  async (id) => {
    try {
      const response = await AxiosInstance.get(`/phone_report/${id}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

// Action to update the location status
export const updateLocationStatus = createAsyncThunk(
  "updateLocationStatus",
  async ({ locationId, updatedData }) => {
    return { locationId, updatedData };
  }
);

const getPhoneReportDetailsSlice = createSlice({
  name: "getPhoneReportDetails",
  initialState: {
    isLoading: false,
    getPhoneReportDetailsData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPhoneReportDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPhoneReportDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getPhoneReportDetailsData = action.payload;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getPhoneReportDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      })
      // Handle the updateLocationStatus action
      .addCase(updateLocationStatus.fulfilled, (state, action) => {
        const { locationId, updatedData } = action.payload;
        const locations =
          state.getPhoneReportDetailsData?.data?.phoneReport?.locations;
        if (locations) {
          // Find the location by ID and update it
          const locationIndex = locations.findIndex(
            (location) => location.id === locationId
          );

          if (locationIndex !== -1) {
            // Update the location data
            locations[locationIndex] = updatedData?.location
          }
        }
      });
  },
});

export default getPhoneReportDetailsSlice.reducer;
