import React, { useState, useEffect } from "react";
import "./pricing.scss";
import { useTranslation } from "react-i18next";
import specialOfferImg from "../../assets/home/special-offer.png";
import pricingPremiumTockenIcon from "../../components/home/pricingPremiumTockenIcon.svg";
import pdfIcon from "../../components/home/pdfIcon.svg";
import downloadIcon from "../../components/home/downloadIcon.svg";
import reportIcon from "../../components/home/reportIcon.svg";
import boxIcon from "../../components/home/boxIcon.svg";
import { Collapse, Select, Space } from "antd";
import { Cookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import { useAppContextInput } from "../../App";
import useScrollToTop from "../customHook/useScrollToTop";
import {
  accordionCollapseIcon,
  accordionExpandIcon,
} from "../../phoneReport/assets/images";

const PricingComponent = (props) => {
  const { productList, allPrice } = props;
  const cookie = new Cookies();
  const cur = cookie.get("currency");
  const lang = cookie.get("lang");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [pricing, setPricing] = useState(cur);
  useEffect(() => {
    if (cur) {
      setPricing(cur);
    }
  }, [cur]);
  const handleChange = (event) => {
    setPricing(event);
    cookie.set("currency", event);
    navigate(`/${lang}/pricing?c=${event}`);
  };

  useScrollToTop();
  const findPriceByTitleAndParameter = (title) => {
    const priceList = allPrice?.data.find((item) => item.title === title);
    return priceList?.currency_options.find(
      (item) => item.parameter === pricing
    );
  };

  const trackingPrice = findPriceByTitleAndParameter("tracking");
  const phoneReport = findPriceByTitleAndParameter("phone_report");
  const premiumReport = findPriceByTitleAndParameter("premium_report");
  const trial = findPriceByTitleAndParameter("trial");
  const pdf = findPriceByTitleAndParameter("download_one_report");
  const unlimitedPDF = findPriceByTitleAndParameter("pdf_download");
  const singlePremiumReport =
    findPriceByTitleAndParameter("unlock_all_section");
  const singlePremiumSection = findPriceByTitleAndParameter(
    "premium_one_section"
  );

  const focusInput = (e) => {
    e.preventDefault();
    navigate(`/${lang}`);
  };

  // Faq Logics
  const [activeKey, setActiveKey] = useState(null);

  const handleCollapseChange = (key) => {
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };

  return (
    <div className="pricing-main-section pricing_page__wrapper">
      <div className="back-grad-title">
        <div className="pricing-main-title">{t("PR_PRICING_EXPLORE")}</div>
      </div>

      <div className="pricing-table-wrapper">
        <div className="container">
          {/* <div className="pricing-table-title">
            <h2>{t("PR_PRICING_EXPLORE")}</h2>
          </div> */}
          <div className="pricing-table-row">
            <div className="pricing-table-col">
              <div className="pricing-inner-box">
                <p>
                  {t("PR_PRICING_TRIAL_AVAILABLE")}{" "}
                  {trial?.symbol + trial?.amount}
                </p>
                <div className="pricing-table-box">
                  <h3>{t("PR_REPORT_PHONE_TRACKING")}</h3>
                  <p>{t("PR_SUBSCRIPTION_TOOLTIP_PHONE_TRACKING")}</p>
                  <div className="pricing-value">
                    {trackingPrice?.amount}{" "}
                    <sub>
                      {trackingPrice?.parameter.toUpperCase().slice(0, 3)}{" "}
                      <span>{t("PR_FOR_MONTH")}</span>
                    </sub>
                  </div>
                  <button className="pricind-btn">
                    {t("PR_START_TRIAL_NOW")}
                  </button>
                </div>
              </div>
            </div>
            <div className="pricing-table-col">
              <div className="pricing-inner-box">
                <p>{t("PR_FREE_TRAL_AVAILABLE")}</p>
                <div className="pricing-table-box">
                  <h3>{t("PR_PHONE_REPORT")}</h3>
                  <p>{t("PR_SUBSCRIPTION_TOOLTIP_PHONE_REPORT_1")}</p>
                  <div className="pricing-value">
                    {phoneReport?.amount}{" "}
                    <sub>
                      {phoneReport?.parameter.toUpperCase().slice(0, 3)}{" "}
                      <span>{t("PR_FOR_MONTH")}</span>
                    </sub>
                  </div>
                  <button className="pricind-btn">
                    {t("PR_PRICING_GET_STARTED")}
                  </button>
                </div>
              </div>
            </div>
            <div className="pricing-table-col">
              <div className="pricing-inner-box">
                <p>{t("PR_PRICING_RECOMMENDED")}</p>
                <div className="pricing-table-box">
                  <h3>
                    <img
                      src={pricingPremiumTockenIcon}
                      alt="pricingPremiumTockenIcon"
                    />
                    {t("PR_PRICING_PREMIUM_REPORTS")}
                  </h3>
                  <p>{t("PR_PRICING_MOTHLY_ACCESS_REPORTS")}</p>
                  <div className="pricing-value">
                    {premiumReport?.amount}{" "}
                    <sub>
                      {premiumReport?.parameter.toUpperCase().slice(0, 3)}{" "}
                      <span>{t("PR_FOR_MONTH")}</span>
                    </sub>
                  </div>
                  <button className="pricind-btn">
                    {t("PR_PRICING_GET_STARTED")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="additional-wrapper">
        <div className="container">
          <div className="additional-wrapper-title">
            <h2>{t("PR_PRICING_ADDITIONAL_ADD_ONS")}</h2>
          </div>
          <div className="additional-row">
            <div className="additional-col">
              <div className="additional-box">
                <div className="additional-icon">
                  <img src={pdfIcon} alt="pdfIcon" />
                </div>
                <h3>{t("PR_PDF_DOWNLOAD")}</h3>
                <p>{t("PR_SUBSCRIPTION_TOOLTIP_PDF")}</p>
                <button>
                  {pdf?.symbol + pdf?.amount} {t("PR_PRICING_FOR_REPORTS")}
                </button>
              </div>
            </div>
            <div className="additional-col">
              <div className="additional-box">
                <div className="additional-icon">
                  <img src={downloadIcon} alt="downloadIcon" />
                </div>
                <h3>{t("PR_PRICING_UNLIMITED_PDF_DOWNLOAD")}</h3>
                <p>{t("PR_PRICING_PDF_DOWNLOAD_DESC")}</p>
                <button>
                  {unlimitedPDF?.symbol + unlimitedPDF?.amount}{" "}
                  {t("PR_FOR_MONTH")}
                </button>
              </div>
            </div>
            <div className="additional-col">
              <div className="additional-box">
                <div className="additional-icon">
                  <img src={reportIcon} alt="reportIcon" />
                </div>
                <h3>{t("PR_PRICING_PREMIUM_REPORT")}</h3>
                <p>{t("PR_PRICING_PDF_PREMIUM_REPORT_DESC")} </p>
                <button>
                  {singlePremiumReport?.symbol + singlePremiumReport?.amount}{" "}
                  {t("PR_PRICING_FOR_REPORTS")}
                </button>
              </div>
            </div>
            <div className="additional-col">
              <div className="additional-box">
                <div className="additional-icon">
                  <img src={boxIcon} alt="boxIcon" />
                </div>
                <h3>{t("PR_PRICING_PREMIUM_SECTION")}</h3>
                <p>{t("PR_PRICING_PDF_PREMIUM_SECTION_DESC")}</p>
                <button>
                  {singlePremiumSection?.symbol + singlePremiumSection?.amount}{" "}
                  {t("PR_PRICING_FOR_REPORTS")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pricing__faws_wrapper">
        <div class="container">
          <div class="pricing-wrapper-title">
            <h2>Frequently Asked Questions</h2>
          </div>
          {/* FAQS */}
          <div className="faqs__accordion_wrapper">
            <div className="inner__collapse_accordion">
              <div class="wpb__accordion_warp">
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "15" ? ["15"] : []}
                    onChange={() => handleCollapseChange("15")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionCollapseIcon : accordionExpandIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("15")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_ACCOUNT_TITLE_1")}
                      key="15"
                    >
                      <p>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_1")}</p>
                      <div>
                        <ul className="faqs__list_content">
                          <li>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_2")}</li>
                          <li>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_3")}</li>
                          <li>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_4")}</li>
                        </ul>
                      </div>
                      <p>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_5")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "6" ? ["6"] : []}
                    onChange={() => handleCollapseChange("6")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionCollapseIcon : accordionExpandIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("6")}
                      />
                    )}
                  >
                    <Collapse.Panel header={t("PR_FAQ_ABOUT_TITLE_6")} key="6">
                      <p>{t("PR_FAQ_ABOUT_DESCRIPTION_6")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "13" ? ["13"] : []}
                    onChange={() => handleCollapseChange("13")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionCollapseIcon : accordionExpandIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("13")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_SUBSCRIPTION_TITLE_2")}
                      key="13"
                    >
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_2_1")}</p>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_2_2")}</p>
                      <div>
                        <ul className="faqs__list_content dot">
                          <li>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_2_3")}</li>
                          <li>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_2_3")}</li>
                          <li>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_2_3")}</li>
                          <li>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_2_3")}</li>
                        </ul>
                      </div>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_2_4")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "14" ? ["14"] : []}
                    onChange={() => handleCollapseChange("14")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionCollapseIcon : accordionExpandIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("14")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_SUBSCRIPTION_TITLE_3")}
                      key="14"
                    >
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_3_1")}</p>
                      <p>
                        {t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_3_1")}{" "}
                        <a href="https://www.reversly.com/en">Reversly.com.</a>
                      </p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "16" ? ["16"] : []}
                    onChange={() => handleCollapseChange("16")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionCollapseIcon : accordionExpandIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("16")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_SUBSCRIPTION_TITLE_5")}
                      key="16"
                    >
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_5")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "17" ? ["17"] : []}
                    onChange={() => handleCollapseChange("17")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionCollapseIcon : accordionExpandIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("17")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_SUBSCRIPTION_TITLE_6")}
                      key="17"
                    >
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_1")}</p>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_2")}</p>
                      <div>
                        <ul className="faqs__list_content dot">
                          <li>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_3")}</li>
                        </ul>
                      </div>
                      <p>
                        <b>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_4")}</b>
                      </p>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_5")}</p>

                      <p>
                        <b>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_6")}</b>
                      </p>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_7")}</p>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_8")}</p>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_9")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "18" ? ["18"] : []}
                    onChange={() => handleCollapseChange("18")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionCollapseIcon : accordionExpandIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("18")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_PRICING_FAQ_TITLE_1")}
                      key="18"
                    >
                      <p>{t("PR_PRICING_FAQ_DESCRIPTION_1")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingComponent;
