import {io} from "socket.io-client";
import {socket_listen_key} from "../environment";

let socket = null;

export const connectSocket = () => {
  if (!socket) {
    socket = io(socket_listen_key);
    console.log("Socket connected");
  }
  console.log("socket connected", socket?.connected);
  return socket;
};
