/* global gtag */
import React, { useState, useEffect } from "react";
import HomePage from "../home";
import "./signUp.scss";
import { Form, Input, Modal, Spin } from "antd";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";
import { Elements } from "@stripe/react-stripe-js";
import {
  getFinalValue,
  getLang,
  renderRTLClass,
  validEmail,
} from "../../utils/commonUtils";
import parsePhoneNumber, {
  isValidPhoneNumber,
  parsePhoneNumberFromString,
} from "libphonenumber-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutComponent from "./module/checkout";
import Notify from "../common/Notify/notify";
import { stripeLoadScript, facebook_id } from "../../environment";
import { useGoogleLogin } from "@react-oauth/google";
import Header from "../header";
import FacebookLogin from "react-facebook-login";
import useScrollToTop from "../customHook/useScrollToTop";

const stripePromise = loadStripe(stripeLoadScript);

const SignUpComponent = (props) => {
  const {
    setData,
    callRegisterData,
    data,
    productList,
    page,
    subscription,
    callsubscriptionCreate,
    callpaymentIntent,
    callgclidData,
    gclid,
    callGclidCheck,
    callSettingBanIp,
    ipData,
    googleOauth2,
    locatePhoneNumberIPQS,
    callSubscriptionDebug,
    sendDetailsData,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const cookie = new Cookies();
  const lang = cookie.get("lang");
  const cur = cookie.get("currency");
  const paramas = cookie.get("param");
  const location = useLocation();
  const trialProduct = productList?.data.find((item) => item.title === "trial");
  const TrialCurr = trialProduct?.currency_options.find(
    (item) => item.parameter === cur
  );
  const subscriptionProduct = productList?.data.find(
    (item) => item.title === "standard"
  );
  const subscriptionProductPrice = subscriptionProduct?.currency_options.find(
    (item) => item.parameter === cur
  );
  const googleRegister = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      let result = await googleOauth2(codeResponse.access_token);
      if (result.type === "googleOauth2/fulfilled") {
        let googleData = {
          currency: data.currency,
          language: data.language,
          email: result.payload.email?.toLowerCase(),
          symbol: data.symbol,
          external_login_type: "google",
          external_login_id: result.payload.id,
        };
        setData((prev) => ({
          ...prev,
          email: result.payload.email?.toLowerCase(),
        }));
        await handleRegister(googleData, "google");
      }
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  // sign with facebook
  const responseFacebook = async (response) => {
    if (!response || response.status === "unknown" || response.error) return;

    if (!response.email) {
      setGoogleErrors(t("FACEBOOK_EMAIL_PERMISSION"));
    } else {
      let facebookData = {
        currency: data.currency,
        language: data.language,
        email: response.email,
        symbol: data.symbol,
        external_login_type: "facebook",
        external_login_id: response.id,
      };
      setData((prev) => ({
        ...prev,
        email: response.email,
      }));
      await handleRegister(facebookData, "google");
    }
  };
  const pageVersion = page === "track" || page === "trace" ? "b" : "w";
  const [clientSecret, setClientSecret] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [payment_intent_id, Set_payment_intent_id] = useState("");
  let emailString = t("ENTER_VALID_EMAIL");
  let modifiedEmailString = emailString.replace(
    /^./,
    emailString[0].toUpperCase()
  );

  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [step, setStep] = useState(1);
  const [formatedNumber, setFormatedNumber] = useState("0");
  const [term, setTerm] = useState(false);
  const [err, setErr] = useState({});
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sawAnimation, setSawAnimation] = useState(false);
  const [useId, setUserId] = useState("");
  const searchNumber = Object.fromEntries([...searchparams]);
  const [googleErrors, setGoogleErrors] = useState();
  const [visibleRows, setVisibleRows] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [loadingComplete, setLoadingComplete] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [displayText, setDisplayText] = useState(
    t("CONNECTING_CELLULAR_TOWERS")
  );
  // const signUpTerms = t("SIGNUP_TERM");
  const signUpTerms = t("SIGNUP_TERM_2");
  var modifiedString = signUpTerms.replace(/\.(?=<\/a>)/g, "");
  const modifiedLink = modifiedString.replace(
    /<a href="https:\/\/reversly\.com\//,
    '<a href="https://reversly.com/' + lang + "/"
  );
  const titlesWithIndices = [
    { title: t("TIMEZONE"), index: 0 },
    { title: t("CARRIER"), index: 1 },
    { title: t("COUNTRY"), index: 2 },
    { title: t("CITY"), index: 3 },
    locatePhoneNumberIPQS?.name !== "N/A"
      ? { title: t("NAME"), index: 4 }
      : undefined,
    { title: t("LOCATION"), index: 5 },
  ].filter((item) => item !== undefined);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
    locale: getLang(lang),
    defaultValues: {
      billingDetails: {
        email: data.email,
      },
    },
  };
  const { phoneNumber: formatedFullNumber } = location.state || {};
  useEffect(() => {
    if (
      location.pathname.includes("track") ||
      location.pathname.includes("trace")
    ) {
      setSawAnimation(true);
      setStep(0);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (sawAnimation) {
      visibleRows.forEach((_, index) => {
        setTimeout(() => {
          setVisibleRows((prevState) => {
            const newState = [...prevState];
            newState[index] = true;
            return newState;
          });
          setTimeout(() => {
            setDisplayText(t("CONNECTING_TO_THE_PHONE"));
          }, 5000);
          if (index === visibleRows.length - 1) {
            setTimeout(() => {
              setLoadingComplete((prevState) => {
                const newState = [...prevState];
                newState[index] = true;
                return newState;
              });

              setTimeout(() => {
                setStep(1);
              }, 2000);
            }, 2000);
          } else {
            setTimeout(() => {
              setLoadingComplete((prevState) => {
                const newState = [...prevState];
                newState[index] = true;
                return newState;
              });
            }, 2000);
          }
        }, index * 2000);
      });
    }
  }, [sawAnimation]);
  useEffect(() => {
    if (searchNumber.n && searchNumber.D) {
      let fullNumber = "+" + searchNumber.D + searchNumber.n;
      const phoneNumber = parsePhoneNumber(fullNumber);
      const mobileNumber = formatedFullNumber
        ? formatedFullNumber
        : searchNumber.n;
      let formatedNumbers =
        "+" + phoneNumber.countryCallingCode + " " + mobileNumber;
      setFormatedNumber(formatedNumbers);
    } else {
      navigate(`/${lang}`);
      setStep(1);
    }
  }, [searchNumber.n, searchNumber.D]);

  useEffect(() => {
    if (TrialCurr && TrialCurr.symbol) {
      setData((prev) => ({
        ...prev,
        symbol: TrialCurr?.symbol,
      }));
    }
  }, [TrialCurr]);
  useEffect(() => {
    setEmail(data.email?.toLowerCase());
  }, [data.email]);

  useEffect(() => {
    document.body.classList.toggle("no-scroll", isModalOpen);
    return () => document.body.classList.remove("no-scroll");
  }, [isModalOpen]);

  const handleCancel = () => {
    setIsModalOpen(false);
    if (page === "track" || page === "trace" || page === "tracking") {
      const urlPrefix = `/${lang}/${page}`;
      if (page !== "tracking" && paramas) {
        cookie.remove("param");
        navigate(
          `${urlPrefix}?${Object.entries(paramas)
            .map(([key, value]) => {
              return value ? `${key}=${value}` : `${key}`;
            })
            .join("&")}`
        );
      } else {
        navigate(`/${lang}/${page}`);
      }
    } else {
      navigate(`/${lang}/`);
    }
    setStep(1);
  };
  useScrollToTop();

  const handleRegister = async (registerData, type) => {
    setIsLoading(true);
    try {
      const result = await callRegisterData(registerData);
      if (result.type === "register/rejected") {
        type === "google"
          ? setGoogleErrors(result.error.message)
          : setEmailError(result.error.message);
        setIsLoading(false);
      } else if (result.type === "register/fulfilled") {
        setEmailError("");
        const { user_id, email } = result.payload.data || {};
        setUserId(user_id);
        gtag("event", "sign_up_email", {
          signup_email: email,
          page_version: pageVersion,
          user_id: user_id,
        });

        if (window.zaraz) {
          window.zaraz.track("signup", {
            email: email,
            user_id: user_id,
          });
        }

        const intentdata = {
          email: email?.toLowerCase(),
          currency: cur.substr(0, 3),
          user_id: user_id,
          plan: window.location.pathname.includes("subscription")
            ? "standard"
            : "trail",
          price: subscriptionProductPrice?.price_id,
          amount: window.location.pathname.includes("subscription")
            ? Number(subscriptionProductPrice?.sent_amount || 0)
            : Number(TrialCurr?.sent_amount || 0),
          country: sendDetailsData?.data?.country_code,
        };

        const paymentResult = await callpaymentIntent(intentdata);
        if (paymentResult.type === "paymentIntent/fulfilled") {
          const { client_secret, subscription_id, payment_intent_id } =
            paymentResult.payload.data || {};
          setClientSecret(client_secret);
          setSubscriptionId(subscription_id);
          Set_payment_intent_id(payment_intent_id);
          setStep(2);
        } else {
          Notify("error", paymentResult.error.message || "Payment failed", "");
        }
      }
    } catch (error) {
      console.error("Error during registration: ", error);
      Notify("error", "Something went wrong", "");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegisterFirst = async () => {
    setIsLoading(true);
    const errors = {};
    // if (!term) {
    //   errors.term = t("ACCEPT_TERMS");
    // }
    gtag("event", "sign_up_email", {
      signup_email: email,
      page_version: pageVersion,
    });
    if (!validEmail.test(email)) {
      if (!email) {
        errors.email = t("ENTER_EMAIL_RESET_PASSWORD");
      } else {
        errors.email = t(modifiedEmailString);
      }
      setIsLoading(false);
    }
    setErr(errors);
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      handleRegister(data, "email");
    } else {
      document.getElementById("input").focus();
    }
  };
  const handleChange = (event) => {
    setErr({});
    setGoogleErrors(null);
    const { name, value } = event.target;
    setData((prev) => ({
      ...prev,
      [name]: value.toLowerCase(),
    }));
    setErr((prev) => ({
      ...prev,
      [name]: "",
    }));
    if (name === "email") {
      setEmailError("");
    }
  };
  const handelCheckterm = (event) => {
    setTerm(event.target.checked);

    if (event.target.checked) {
      setErr((prevState) => ({ ...prevState, term: "" }));
    }
  };

  return (
    <>
      {page === "track" || page === "trace" ? (
        <>
          <Header />
          <HomePage />
        </>
      ) : (
        <HomePage />
      )}

      <Modal
        centered
        open={isModalOpen}
        onCancel={handleCancel}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        maskClosable={false}
        closable={!isLoading && step !== 0}
        className={`${renderRTLClass()} login_btn__wrapper  ${
          step === 0 ? "animation-model" : "locating-login-modal"
        } ${step === 1 ? "signup__locating_wrap" : ""} ${
          step === 2 ? "checkout_payment_modal" : ""
        } ${step === 1 ? "signup-info-wrap" : ""}`}
      >
        <div className="login-modal-section">
          {step !== 0 && (
            <div className="modal_number_info">
              <div
                className="login-modal-title"
                style={{ flexDirection: "column" }}
              >
                {sawAnimation ? (
                  <p>{t("NUMBER_LOCATED")}</p>
                ) : (
                  <p>{t("PR_SEARCHING")}</p>
                  // TEMPORARY STOP
                  // <p>{t("SIGN_UP_MODAL_TITLE")}</p>
                )}
                <div className="number display-inputs-rtl">
                  <p>{searchNumber.D ? formatedNumber : `00 00 000 0000`}</p>
                </div>
              </div>
            </div>
          )}
          <div className="wrap-top-modal">
            {step === 0 ? (
              <div className="animation__main_wrapper">
                <div className="animation_wrapper">
                  <div className="animation__title">
                    <div className="animation__text">
                      <p>{displayText}</p>
                    </div>
                    <div className="locate__number display-inputs-rtl">
                      <span>
                        <a href="tel:+91 8140-081827">
                          {searchNumber.D ? formatedNumber : `00 00 000 0000`}
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="animated__table-list">
                    {titlesWithIndices.map((item, renderIndex) => (
                      <div
                        key={renderIndex}
                        className={`animated__table-row ${
                          visibleRows[renderIndex] ? "visible" : "hidden"
                        }`}
                      >
                        <div className="animated__table-title">
                          {item.title}
                        </div>
                        <div
                          className={`animated__table-value ${
                            loadingComplete[renderIndex] ? "" : "loading"
                          }`}
                        >
                          {loadingComplete[renderIndex]
                            ? getFinalValue(
                                item.index,
                                t,
                                locatePhoneNumberIPQS
                              )
                            : ""}
                          {!loadingComplete[renderIndex] && (
                            <svg
                              className="rotating-border"
                              viewBox="0 0 100 100"
                            >
                              <circle
                                className="circle"
                                cx="50"
                                cy="50"
                                r="45"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : step === 1 ? (
              <Spin spinning={isLoading}>
                <Form>
                  <div className="body-modal">
                    <div className="body-modal-ceate-account">
                      {sawAnimation ? (
                        <p>{t("SIGN_UP_EXACT_LOCATION")}</p>
                      ) : (
                        <p>{t("CREATE_ACCOUNT_TITLE")}</p>
                      )}
                    </div>
                    <div className="google-button-login space-gpay">
                      <button
                        className="login-with-google-btn"
                        type="button"
                        onClick={() => {
                          googleRegister();
                          setErr({});
                        }}
                        style={{ marginBottom: 10 }}
                      >
                        {t("CONTINUE_WITH_GOOGLE")}
                      </button>
                      {/* <button
                        className="login-with-google-btn login-with-apple-btn"
                        type="button"
                      >
                        {t('CONTINUE_WITH_APPLE')}
                      </button> */}
                      <FacebookLogin
                        appId={facebook_id}
                        fields="name, email"
                        scope="email"
                        callback={responseFacebook}
                        cssClass="login-with-google-btn login-with-facebook-btn "
                        textButton={t("CONTINUE_WITH_FACEBOOK")}
                        isMobile="false"
                        disableMobileRedirect={true}
                      />
                    </div>
                    <div className="auth-divider">
                      <span className="line left"></span>
                      <span className="or-text">
                        {t("OR_SIGNUP_WITH_EMAIL")}
                      </span>
                      <span className="line right"></span>
                    </div>
                    <div className="modal-login-form">
                      <Input
                        name="email"
                        onChange={handleChange}
                        id="input"
                        placeholder="hello@mail.com"
                        className={`input ${
                          err.email || emailError ? "errorInput" : ""
                        }`}
                      />
                      {err.email && (
                        <div style={{ color: "#ff4853" }}>{err.email}</div>
                      )}
                      {emailError && (
                        <div style={{ color: "#ff4853" }}>{emailError}</div>
                      )}
                      {!emailError && googleErrors && (
                        <p style={{ margin: 5, color: "#ff4853" }}>
                          {googleErrors}
                        </p>
                      )}
                    </div>
                    <div
                      className="submit-btn-modal"
                      onClick={handleRegisterFirst}
                    >
                      <button
                        className=" on-mobile"
                        type="submit"
                        disabled={isLoading}
                      >
                        {t("SIGN_UP_MODAL_SUBMIT")}
                      </button>
                      <button
                        className="hl_cta_wrap on-desk"
                        type="submit"
                        disabled={isLoading}
                      >
                        {t("SIGN_UP_MODAL_SUBMIT")}
                      </button>
                    </div>
                    <div className="term-checkbox">
                      {/* <input type="checkbox" checked={term} onChange={handelCheckterm} />
            {err.term && (
            <div className="sign-up-tooltip">
                Please read and accept Terms and Conditions
            </div>
            )} */}
                      {!page && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: modifiedLink,
                          }}
                        ></span>
                      )}
                    </div>
                  </div>
                </Form>
              </Spin>
            ) : (
              step === 2 &&
              clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <Spin spinning={isLoading}>
                    <CheckoutComponent
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      data={data}
                      TrialCurr={TrialCurr}
                      subscriptionProductPrice={subscriptionProductPrice}
                      callsubscriptionCreate={callsubscriptionCreate}
                      subscription={subscription}
                      page={page}
                      initiaClientSecret={clientSecret}
                      subscriptionId={subscriptionId}
                      callgclidData={callgclidData}
                      gclid={gclid}
                      callGclidCheck={callGclidCheck}
                      useId={useId}
                      senderNumberCountry={searchNumber.D}
                      senderNumber={searchNumber.n}
                      callSettingBanIp={callSettingBanIp}
                      ipData={ipData}
                      paramas={paramas}
                      callSubscriptionDebug={callSubscriptionDebug}
                      payment_intent_id={payment_intent_id}
                    />
                  </Spin>
                </Elements>
              )
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
export default SignUpComponent;