import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";

import AccessReportComponent from "./accessReport";
import { getPhoneReportDetails, sendLocation } from "../../redux/slice";
import { createFirstReport } from "../../redux/slice/createFirstReportSlice";
import PremiumPage from "../premiumPage";
import { getPricingTitleList } from "../../redux/slice/getPricingTitleListSlice";
import LocatingModal from "../../components/partials/Modals/locatingModal";
import { Cookies } from "react-cookie";
import Loader from "../../components/common/loader/loader";
import Notify from "../../../components/common/Notify/notify";
import { subscriptionStatus } from "../../redux/slice/subscriptionStatusSlice";

const AccessReport = ({
  callGetPhoneReportDetails,
  phoneReportDetails,
  callGetPricing,
  callCheckSubscriptionStatus
}) => {
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const step = cookies.get("step");

  const [isLoading, setIsLoading] = useState(true);
  const [isComponentDecided, setIsComponentDecided] = useState(false);
  const [openLocateModal, setOpenLocateModal] = useState(false);
  const [phoneReportModalShow, setPhoneReportModalShow] = useState(false);
  const [defaultAccess, setDefaultAccess] = useState(false);
  const [fullAccess, setFullAccess] = useState(false);
  const [number, setNumber] = useState("");
  const [countryCode, setCountryCode] = useState(+1);
  const [phoneReportId, setPhoneReportId] = useState(0);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  // Access Class
  useEffect(() => {
    const url = window.location.pathname;
    if (url.includes("access-report") && fullAccess) {
      const root = document.getElementById("root");
      root.classList.add("access_inner_wrapper_mrg_sec");
    } else {
      const root = document.getElementById("root");
      root.classList.remove("access_inner_wrapper_mrg_sec");
    }
  }, [window.location.pathname, fullAccess]);

  const fetchPhoneReportDetails = useCallback(
    async (id) => {
      setIsLoading(true);
      const reportId = searchNumber.id || phoneReportId || id;
      if (reportId) {
        setPhoneReportId(reportId);
        try {
          const response = await callGetPhoneReportDetails(reportId);
          if (response.type === "getPhoneReportDetails/fulfilled") {
            const status = response?.payload?.data?.phoneReport?.status;
            const isPhoneReport = response?.payload?.data?.isPhoneReport;
            if (
              status === "unlocked" ||
              status === "partially" ||
              (status === "locked" && isPhoneReport)
            ) {
              setFullAccess(true);
              setDefaultAccess(false);
            } else if (status === "locked" && !isPhoneReport) {
              setDefaultAccess(true);
            } else {
              setDefaultAccess(true);
            }

            setIsComponentDecided(true);
          } else {
            navigate(`/${lang}/dashboard`);
          }
        } catch (error) {
          console.error("Error fetching phone report details:", error);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [searchNumber.id, phoneReportId, callGetPhoneReportDetails]
  );

  useEffect(() => {
    if (searchNumber.id) {
      fetchPhoneReportDetails(searchNumber.id);
    } else {
      setDefaultAccess(true);
      setIsLoading(false);
      setIsComponentDecided(true);
      if (!openLocateModal && step) {
        navigate(`/${lang}/dashboard`);
      }
    }
  }, [searchNumber.id]);

  useEffect(() => {
    callGetPricing();
  }, [callGetPricing]);

  const closeLocatingModal = () => {
    setOpenLocateModal(false);
    if (!phoneReportId) {
      navigate(`/${lang}/dashboard`);
    }
  };

  return (
    <>
      {isLoading || !isComponentDecided ? (
        <Loader />
      ) : (
        (() => {
          const props = {
            setOpenLocateModal: setOpenLocateModal,
            senderNumber: number,
            phoneReportModalShow: phoneReportModalShow,
            searchNumber: searchNumber,
            fetchPhoneReportDetails: fetchPhoneReportDetails,
            phoneReportDetails:
              phoneReportDetails?.getPhoneReportDetailsData?.data,
            countryCode: countryCode,
            phoneReportId: phoneReportId,
            callCheckSubscriptionStatus: callCheckSubscriptionStatus,
            setPhoneReportModalShow: setPhoneReportModalShow
          };

          if (defaultAccess) {
            return <AccessReportComponent {...props} />;
          } else if (fullAccess) {
            return <PremiumPage {...props} />;
          } else {
            return <AccessReportComponent {...props} />;
          }
        })()
      )}
      <LocatingModal
        isOpen={openLocateModal}
        setIsOpen={setOpenLocateModal}
        onClose={closeLocatingModal}
        setNumber={setNumber}
        number={number}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        setPhoneReportId={setPhoneReportId}
        phoneReportId={phoneReportId}
        setPhoneReportModalShow={setPhoneReportModalShow}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sendLocation: state.sendLocation,
    sendDetailsData: state.sendDetailsData,
    phoneReportDetails: state.getPhoneReportDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callGetPhoneReportDetails: (data) => dispatch(getPhoneReportDetails(data)),
    callCreateFirstReport: (data) => dispatch(createFirstReport(data)),
    callGetPricing: (data) => dispatch(getPricingTitleList(data)),
    callSendLocation: (data) => dispatch(sendLocation(data)),
    callCheckSubscriptionStatus: (data) => dispatch(subscriptionStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessReport);
