import React from "react";
import PricingComponent from "./pricing";
import { connect } from "react-redux";

const Pricing = (props) => {
  const { productList, productListSingle } = props;

  return <PricingComponent productList={productListSingle} allPrice={productList} />;
};

const mapStateToProps = (state) => {
  return {
    productList: state.getProductList.productListData,
    productListSingle: state.getProductListSingle.productListSingleData,
  };
};

export default connect(mapStateToProps, null)(Pricing);
