import { Collapse, Drawer } from "antd";
import "./faqComponent.scss";
import React, { useState } from "react";
import {
  accordionExpandIcon,
  accordionCollapseIcon,
  faqCategoriesIcon,
} from "../../assets/images";
import { t } from "i18next";

const FaqComponent = () => {
  const [activeKey, setActiveKey] = useState(null);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const handleCollapseChange = (key) => {
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };

  return (
    <div className="main_root">
      <div className="main_container">
        <div className="faqs__accordion_wrapper">
          <div className="faqs__accordion_title">
            <h2>{t("FAQ_ASKED_QUESTION_TITLE")}</h2>
            <p>{t("FAQ_DESCRIPTION")}</p>
          </div>
          <div className="bs_accordion__warp">
            <div className="inner__overmenu_wrapper">
              <div className="accordion-left-sidebar">
                <div className="bs__menu_title">
                  <span>
                    <img
                      src={faqCategoriesIcon}
                      width="24"
                      height="24"
                      alt="FAQ Categories Icon"
                    />
                    {t("FAQ_CATEGORIES")}
                  </span>
                </div>
                <div className="bs__menu_content">
                  <ul className="bs__menu_list">
                    <li className="bs__list_item">
                      <a className="bs__menu_item" href="#menuitem1">
                        {t("PR_ABOUT_REVERSLY")}
                      </a>
                    </li>
                    <li className="bs__list_item">
                      <a className="bs__menu_item" href="#menuitem2">
                        {t("PR_FAQ_SUBSCRIPTION_BILLING")}
                      </a>
                    </li>
                    <li className="bs__list_item">
                      <a className="bs__menu_item" href="#menuitem3">
                        {t("PR_FAQ_MY_ACCOUNT")}
                      </a>
                    </li>
                    <li className="bs__list_item">
                      <a className="bs__menu_item" href="#menuitem4">
                        {t("PR_FAQ_PRIVACY")}
                      </a>
                    </li>
                    <li className="bs__list_item">
                      <a className="bs__menu_item" href="#menuitem5">
                        {t("PR_TECHNICAL_SUPPORT")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="inner__collapse_accordion">
              <div className="wpb__accordion_warp" id="menuitem1">
                <div>
                  <h2 className="accordion_header_title">
                    {t("PR_ABOUT_REVERSLY")}
                  </h2>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "1" ? ["1"] : []}
                    onChange={() => handleCollapseChange("1")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("1")}
                      />
                    )}
                  >
                    <Collapse.Panel header={t("PR_KNOW_TITLE_1")} key="1">
                      <p>{t("PR_KNOW_DESCRIPTION_1")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "2" ? ["2"] : []}
                    onChange={() => handleCollapseChange("2")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("2")}
                      />
                    )}
                  >
                    <Collapse.Panel header={t("PR_KNOW_TITLE_2")} key="2">
                      <p>{t("PR_KNOW_DESCRIPTION_2")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "3" ? ["3"] : []}
                    onChange={() => handleCollapseChange("3")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("3")}
                      />
                    )}
                  >
                    <Collapse.Panel header={t("PR_KNOW_TITLE_3")} key="3">
                      <p>{t("PR_KNOW_DESCRIPTION_3")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "4" ? ["4"] : []}
                    onChange={() => handleCollapseChange("4")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("4")}
                      />
                    )}
                  >
                    <Collapse.Panel header={t("PR_KNOW_TITLE_4")} key="4">
                      <p>{t("PR_KNOW_DESCRIPTION_4")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "5" ? ["5"] : []}
                    onChange={() => handleCollapseChange("5")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("5")}
                      />
                    )}
                  >
                    <Collapse.Panel header={t("PR_KNOW_TITLE_5")} key="5">
                      <p>{t("PR_KNOW_DESCRIPTION_5")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "6" ? ["6"] : []}
                    onChange={() => handleCollapseChange("6")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("6")}
                      />
                    )}
                  >
                    <Collapse.Panel header={t("PR_FAQ_ABOUT_TITLE_6")} key="6">
                      <p>{t("PR_FAQ_ABOUT_DESCRIPTION_6")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "7" ? ["7"] : []}
                    onChange={() => handleCollapseChange("7")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("7")}
                      />
                    )}
                  >
                    <Collapse.Panel header={t("PR_FAQ_ABOUT_TITLE_7")} key="7">
                      <p>{t("PR_FAQ_ABOUT_DESCRIPTION_7")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "8" ? ["8"] : []}
                    onChange={() => handleCollapseChange("8")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("8")}
                      />
                    )}
                  >
                    <Collapse.Panel header={t("PR_FAQ_ABOUT_TITLE_8")} key="8">
                      <p>{t("PR_FAQ_ABOUT_DESCRIPTION_8")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "9" ? ["9"] : []}
                    onChange={() => handleCollapseChange("9")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("9")}
                      />
                    )}
                  >
                    <Collapse.Panel header={t("PR_FAQ_ABOUT_TITLE_9")} key="9">
                      <p>{t("PR_FAQ_ABOUT_DESCRIPTION_9")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "10" ? ["10"] : []}
                    onChange={() => handleCollapseChange("10")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("10")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_ABOUT_TITLE_10")}
                      key="10"
                    >
                      <p>{t("PR_FAQ_ABOUT_DESCRIPTION_10")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </div>
              <div className="wpb__accordion_warp" id="menuitem2">
                <div>
                  <h2 className="accordion_header_title">
                    {t("PR_FAQ_SUBSCRIPTION_BILLING")}
                  </h2>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "12" ? ["12"] : []}
                    onChange={() => handleCollapseChange("12")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("12")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_SUBSCRIPTION_TITLE_1")}
                      key="12"
                    >
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_1")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "13" ? ["13"] : []}
                    onChange={() => handleCollapseChange("13")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("13")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_SUBSCRIPTION_TITLE_2")}
                      key="13"
                    >
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_2_1")}</p>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_2_2")}</p>
                      <div>
                        <ul className="faqs__list_content dot">
                          <li>DESCRIPTOR</li>
                          <li>DESCRIPTOR</li>
                          <li>DESCRIPTOR</li>
                          <li>DESCRIPTOR</li>
                        </ul>
                      </div>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_2_4")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "14" ? ["14"] : []}
                    onChange={() => handleCollapseChange("14")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("14")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_SUBSCRIPTION_TITLE_3")}
                      key="14"
                    >
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_3_1")}</p>
                      <p>
                        {t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_3_2")}{" "}
                        <a href="https://www.reversly.com/en">Reversly.com.</a>
                      </p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "15" ? ["15"] : []}
                    onChange={() => handleCollapseChange("15")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("15")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_SUBSCRIPTION_TITLE_4")}
                      key="15"
                    >
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_4_1")}</p>
                      <div>
                        <ul className="faqs__list_content">
                          <li
                            dangerouslySetInnerHTML={{
                              __html: t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_4_2"),
                            }}
                          ></li>
                          <li>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_4_3")}</li>
                          <li
                            dangerouslySetInnerHTML={{
                              __html: t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_4_4"),
                            }}
                          ></li>
                        </ul>
                      </div>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_4_5")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "16" ? ["16"] : []}
                    onChange={() => handleCollapseChange("16")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("16")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_SUBSCRIPTION_TITLE_5")}
                      key="16"
                    >
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_5")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "17" ? ["17"] : []}
                    onChange={() => handleCollapseChange("17")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("17")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_SUBSCRIPTION_TITLE_6")}
                      key="17"
                    >
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_1")}</p>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_2")}</p>
                      <div>
                        <ul className="faqs__list_content dot">
                          <li>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_3")}</li>
                        </ul>
                      </div>
                      <p>
                        <b>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_4")}</b>
                      </p>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_5")}</p>
                      <p>
                        <b>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_6")}</b>
                      </p>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_7")}</p>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_8")}</p>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_9")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </div>
              <div className="wpb__accordion_warp" id="menuitem3">
                <div>
                  <h2 className="accordion_header_title">
                    {t("PR_FAQ_MY_ACCOUNT")}
                  </h2>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "20" ? ["20"] : []}
                    onChange={() => handleCollapseChange("20")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("20")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_ACCOUNT_TITLE_1")}
                      key="20"
                    >
                      <p>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_1")}</p>
                      <div>
                        <ul className="faqs__list_content">
                          <li>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_2")}</li>
                          <li>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_3")}</li>
                          <li>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_4")}</li>
                        </ul>
                      </div>
                      <p>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_5")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "21" ? ["21"] : []}
                    onChange={() => handleCollapseChange("21")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("21")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_ACCOUNT_TITLE_2")}
                      key="21"
                    >
                      <p>{t("PR_FAQ_ACCOUNT_DESCRIPTION_2_1")}</p>
                      <div>
                        <p>
                          <b>1. {t("PR_FAQ_ACCOUNT_DESCRIPTION_2_2")}</b>
                        </p>
                        <ul className="faqs__list_content dot">
                          <li
                            dangerouslySetInnerHTML={{
                              __html: t("PR_FAQ_ACCOUNT_DESCRIPTION_2_3"),
                            }}
                          ></li>
                        </ul>
                      </div>
                      <div>
                        <p>
                          <b>2. {t("PR_FAQ_ACCOUNT_DESCRIPTION_2_4")}</b>
                        </p>
                        <ul className="faqs__list_content dot">
                          <li
                            dangerouslySetInnerHTML={{
                              __html: t("PR_FAQ_ACCOUNT_DESCRIPTION_2_5"),
                            }}
                          ></li>
                          <li>{t("PR_FAQ_ACCOUNT_DESCRIPTION_2_6")}</li>
                        </ul>
                      </div>
                      <div>
                        <p>
                          <b>3. {t("PR_FAQ_ACCOUNT_DESCRIPTION_2_7")}</b>
                        </p>
                        <ul className="faqs__list_content dot">
                          <li
                            dangerouslySetInnerHTML={{
                              __html: t("PR_FAQ_ACCOUNT_DESCRIPTION_2_8"),
                            }}
                          ></li>
                          <li>{t("PR_FAQ_ACCOUNT_DESCRIPTION_2_9")}</li>
                        </ul>
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "22" ? ["22"] : []}
                    onChange={() => handleCollapseChange("22")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("22")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_ACCOUNT_TITLE_3")}
                      key="22"
                    >
                      <p>{t("PR_FAQ_ACCOUNT_DESCRIPTION_3_1")}</p>
                      <div>
                        <ul className="faqs__list_content dot">
                          <li>{t("PR_FAQ_ACCOUNT_DESCRIPTION_3_2")}</li>
                          <li>{t("PR_FAQ_ACCOUNT_DESCRIPTION_3_3")}</li>
                          <li>{t("PR_FAQ_ACCOUNT_DESCRIPTION_3_4")}</li>
                          <li>{t("PR_FAQ_ACCOUNT_DESCRIPTION_3_5")}</li>
                        </ul>
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </div>
              <div className="wpb__accordion_warp" id="menuitem4">
                <div>
                  <h2 className="accordion_header_title">
                    {t("PR_FAQ_PRIVACY")}
                  </h2>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "23" ? ["23"] : []}
                    onChange={() => handleCollapseChange("23")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("23")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_PRIVACY_TITLE_1")}
                      key="23"
                    >
                      <p>{t("PR_FAQ_PRIVACY_DESCRIPTION_1_1")}</p>
                      <p>{t("PR_FAQ_PRIVACY_DESCRIPTION_1_2")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "24" ? ["24"] : []}
                    onChange={() => handleCollapseChange("24")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("24")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_PRIVACY_TITLE_2")}
                      key="24"
                    >
                      <p>{t("PR_FAQ_PRIVACY_DESCRIPTION_2_1")}</p>
                      <p>{t("PR_FAQ_PRIVACY_DESCRIPTION_2_2")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "25" ? ["25"] : []}
                    onChange={() => handleCollapseChange("25")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("25")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_PRIVACY_TITLE_3")}
                      key="25"
                    >
                      <p>
                        {t("PR_FAQ_PRIVACY_DESCRIPTION_3")}{" "}
                        <a href="https://www.reversly.com/en">Reversly.com</a>.
                      </p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "26" ? ["26"] : []}
                    onChange={() => handleCollapseChange("26")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("26")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_PRIVACY_TITLE_4")}
                      key="26"
                    >
                      <p>{t("PR_FAQ_PRIVACY_DESCRIPTION_4")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "27" ? ["27"] : []}
                    onChange={() => handleCollapseChange("27")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("27")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_PRIVACY_TITLE_5")}
                      key="27"
                    >
                      <p>{t("PR_FAQ_PRIVACY_DESCRIPTION_5")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "28" ? ["28"] : []}
                    onChange={() => handleCollapseChange("28")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("28")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_PRIVACY_TITLE_6")}
                      key="28"
                    >
                      <p>{t("PR_FAQ_PRIVACY_DESCRIPTION_6")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </div>
              <div className="wpb__accordion_warp" id="menuitem5">
                <div>
                  <h2 className="accordion_header_title">
                    {t("PR_TECHNICAL_SUPPORT")}
                  </h2>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "29" ? ["29"] : []}
                    onChange={() => handleCollapseChange("29")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("29")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={
                        <span style={{ textTransform: "none" }}>
                          {t("CONTACT_US_EMAIL")}
                        </span>
                      }
                      key="29"
                    >
                      <p>{t("PR_FAQ_SUPPORT_DESCRIPTION_1")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`menu_bg_wrapper menu-wrapper-mobile ${
          open ? "active" : ""
        }`}
      >
        <div onClick={showDrawer} className="fixed-mobile-menu">
          <div className="mobile-active-menu">
            <img
              src={faqCategoriesIcon}
              width="24"
              height="24"
              alt="FAQ Categories Icon"
            />
            <p>{t("FAQ_CATEGORIES")}</p>
          </div>
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 24 24"
            class="mobile-menu-updown-icon"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M18.2073 9.04304 12.0002 2.83594 5.79312 9.04304 7.20733 10.4573 12.0002 5.66436 16.7931 10.4573 18.2073 9.04304ZM5.79297 14.9574 12.0001 21.1646 18.2072 14.9574 16.793 13.5432 12.0001 18.3361 7.20718 13.5432 5.79297 14.9574Z"></path>
          </svg>
          {/* UP DOWN ARROW */}
        </div>
        <Drawer
          title=""
          placement={"bottom"}
          closable={false}
          onClose={onClose}
          open={open}
          key={"bottom"}
          className="sidebar-mobile-menu"
        >
          <div className="menu-head-mobile" onClick={onClose}>
            {/* <IoCloseOutline /> */}
            <p>{t("PR_CLOSE_MENU")}</p>
          </div>
          <div className="overview_menu-title menu-title-mobile">
            <ul className="bs__menu_list">
              <li className="bs__menu_item" id="menuitem1" onClick={onClose}>
                <a href="#menuitem1">{t("PR_ABOUT_REVERSLY")}</a>
              </li>
              <li className="bs__menu_item" id="menuitem2" onClick={onClose}>
                <a href="#menuitem2">{t("PR_FAQ_SUBSCRIPTION_BILLING")}</a>
              </li>
              <li className="bs__menu_item" id="menuitem3" onClick={onClose}>
                <a href="#menuitem3">{t("PR_FAQ_MY_ACCOUNT")}</a>
              </li>
              <li className="bs__menu_item" id="menuitem4" onClick={onClose}>
                <a href="#menuitem4">{t("PR_FAQ_PRIVACY")}</a>
              </li>
              <li className="bs__menu_item" id="menuitem5" onClick={onClose}>
                <a href="#menuitem5">{t("PR_TECHNICAL_SUPPORT")}</a>
              </li>
            </ul>
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default FaqComponent;
