import React, { useEffect, useState } from "react";
import { dsFooterLogo } from "../../../assets/images";
import "./footer.scss";
import { Link } from "react-router-dom";
import { Cookies } from "react-cookie";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import LanguageChangeModal from "../../partials/Modals/languageChangeModal/languageChangeModal";
import { languages } from "../../../../utils/commonUtils";

const PhoneReportFooter = () => {
  const currentYear = new Date().getFullYear();
  const cookies = new Cookies();
  const cookieLang = cookies.get("lang");
  const { t } = useTranslation();

  const [lang, setLang] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showLogo, setShowLogo] = useState("gb");

  useEffect(() => {
    const currentLangCode = window.location.pathname.split("/")[1];
    const language = languages.find((item) => item.code === currentLangCode);
    if (language) {
      cookies.set("langlogo", language.flagCode);
      setShowLogo(language.flagCode);
    }
  }, [lang, window.location.pathname, cookies]);

  useEffect(() => {
    const cookieLang = cookies.get("lang");
    const browserLanguage = navigator.language || navigator.userLanguage;
    setLang(cookieLang ? cookieLang : browserLanguage);
  }, [cookies, window.location]);
  const onenLanguage = () => {
    setIsModalOpen(true);
    document.body.classList.add("body_langpopup__wrapper");
  };
  const closeLanguage = () => {
    setIsModalOpen(false);
    document.body.classList.remove("body_langpopup__wrapper");
  };

  return (
    <footer className="site_footer">
      <div className="site_footer_padding">
        <div className="main_container_footer">
          <div className="ds-footer-top-content">
            <p>{t("PR_ACCESS_REPORT_TEXT_2")}</p>
          </div>
          <div className="ds-footer-bottom-content">
            {showLogo && (
              <div className="language-container" onClick={onenLanguage}>
                <div className="language-box">
                  <ReactCountryFlag
                    countryCode={showLogo}
                    svg
                    style={{
                      width: "16px",
                      height: "16px",
                      borderRadius: "60px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            )}
            <Link to={`/${cookieLang}/dashboard`}>
              <div className="ds-footer-logo">
                <img src={dsFooterLogo} alt="" height={20} width={20} />
              </div>
            </Link>
            <div className="ds-copyright-footer">
              <p>
                &copy; {currentYear} - {t("PR_ACCESS_REPORT_TEXT_3")}
              </p>
            </div>
            <div className="ds-footer-conditions">
              <ul>
                <li>
                  <Link to={`/${cookieLang}/privacy-policy`} target="_blank">
                    {t("FOOTER_PRIVACY_POLICY")}
                  </Link>
                </li>
                <li>
                  <Link to={`/${cookieLang}/terms`} target="_blank">
                    {t("PR_TC_TERMS_CONDITION")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <LanguageChangeModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        currentLang={lang}
        setLang={setLang}
        closeLanguage={closeLanguage}
      />
    </footer>
  );
};

export default PhoneReportFooter;
