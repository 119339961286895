import React, { useEffect } from "react";
import "./privacyPolicy.scss";
import { scrollToTop } from "../../utils/commonUtils";
import { t } from "i18next";
const PrivacyPolicy = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="main_root">
      <div className="Policy-header-section">
        <div className="main_container">
          <h1 className="Policy-title">{t("FOOTER_PRIVACY_POLICY")}</h1>
        </div>
      </div>
      <div className="wpb_info_wrapper">
        <div className="main_container">
          <div className="cms__poolicy_wrapper">
            {!["en"].includes(window.location.pathname.split("/")[1]) && (
              <strong class="policy_desc mb-2">
                {t("PP_TERMS_PRIVACY_NOTE")}
              </strong>
            )}
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">1. {t("PR_PRIVACY_POLICY")}</h2>
              <p class="policy_desc mb-1">
                {t("PR_PRIVACY_POLICY_DESC")}
                <a href="https://www.reversly.com/en/">(www.Reversly.com)</a>.
              </p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">2. {t("PR_INFO_WE_COLLECT")}</h2>
              <p class="policy_desc mb-1">{t("PR_INFO_WE_COLLECT_DESC_1")}:</p>
              <p class="policy_desc mb-1">
                <b>{t("PR_WEBSITE_VISITORS")}:</b>{" "}
                {t("PR_WEBSITE_VISITORS_DESC")}
              </p>
              <p class="policy_desc mb-1">
                <b>{t("PR_CUSTOMERS_TITLE")}:</b> {t("PR_CUSTOMERS_DESC")}
              </p>
              <p class="policy_desc mb-1">
                <b>{t("PR_SERVICES_INFO")}:</b> {t("PR_SERVICES_INFO_DESC")} (a) {t("PR_RELIABLE_THIRDPARTIES")}; (b) {t("PR_THE_INTERNET")}; (c) {t("PR_PUBLICALY_SOURCES")}:
              </p>
              <ul class="policy__item">
                <li>{t("PR_NAME_ALIAS")}</li>
                <li>{t("PR_POSTAL_ADDRESS")}</li>
                <li>{t("PR_PHONE_NUMBERS")}</li>
                <li>{t("PR_GENDER")}</li>
                <li>{t("PR_ASSOCIATES")}</li>
                <li>{t("PR_EMAIL_ADDRESSES")}</li>
                <li>{t("PR_EDUCATION_DEGREES")}</li>
                <li>{t("PR_EMP_HISTORY")}</li>
                <li>{t("PR_LANGUAGES")}</li>
                <li>{t("PR_SOCIAL_MEDIA_PROFILE")}</li>
                <li>{t("PR_SOCIAL_MEDIA_IMAGES")}</li>
                <li>{t("PR_DECEASED_DATA")}</li>
                <li>{t("PR_HOME_OWNERSHIP")}</li>
                <li>{t("PR_PR_DOB_AGE")}</li>
                <li>{t("PR_ASSOCIATED_WEB_PAGES")}</li>
              </ul>
              <p class="policy_desc mb-1">{t("PR_INFO_SOURCES")}</p>
              <ul class="policy__item">
                <li>
                  <b>{t("PR_PUBLIC_INFO")}</b> – {t("PR_PUBLIC_INFO_SOURCES")}
                </li>
                <li>
                  <b>{t("PR_THIRD_PARTY_SOURCES")}</b> – {t("PR_LICENSED_INFO")}
                </li>
                <li>
                  <b>{t("PR_CONSUMER_PROVIDED_INFO")}</b> –{" "}
                  {t("PR_WEBSITE_PROVIDED_INFO")}
                </li>
              </ul>
              <p class="policy_desc mb-1">{t("PR_NO_WARRANTY_INFO")}.</p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">3. {t("PR_HOW_WE_SHARE_INFO")}</h2>
              <p class="policy_desc mb-1">
                {t("PR_SHARE_PERSONAL_INFO_WITH")}:
              </p>
              <ul class="policy__item">
                <li>{t("PR_AFFILIATES_OF")}</li>
                <li>{t("PR_SERVICE_PROVIDERS")}</li>
                <li>{t("PR_SHARE_WITH_CUSTOMERS")}.</li>
              </ul>
              <p class="policy_desc mb-1">{t("PR_LEGAL_DISCLOSURE")}.</p>
              <div class="table-container">
                <table class="responsive-table">
                  <thead>
                    <tr>
                      <th>{t("PR_PURPOSE")}</th>
                      <th>{t("PR_LEGAL_BASIS")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <strong>{t("PR_SERVICES_SUPPORT")}.</strong>{" "}
                          {t("PR_USE_PERSONAL_INFO")}.
                        </p>
                      </td>
                      <td>
                        <p>{t("PR_CONTRACT_LEGAL_OBLIGATIONS")}.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>{t("PR_PAYMENTS")}.</strong>{" "}
                          {t("PR_PAYMENT_PROCESSORS_INFO")}.
                        </p>
                      </td>
                      <td>
                        <p>{t("PR_PROVISION_OF_SERVICES")}.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>{t("PR_IMPROVE_SERVICES")}.</strong>{" "}
                          {t("PR_IMPROVE_SERVICES_DESC")}.
                        </p>
                      </td>
                      <td>
                        <p>{t("PR_IMPROVING_SERVICES")}.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>{t("PR_MARKETING_ADVERTISING")}.</strong>{" "}
                          {t("PR_MARKETING_ADVERTISING_DESC")}.
                        </p>
                      </td>
                      <td>
                        <p>{t("PR_TAILORED_SERVICES")}.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>{t("PR_INTEGRITY_SECURITY")}.</strong>{" "}
                          {t("PR_INTEGRITY_SECURITY_DESC")}.
                        </p>
                      </td>
                      <td>
                        <p>{t("PR_TAILORED_SERVICES")}.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>{t("PR_SERVICES")}.</strong>{" "}
                          {t("PR_SERVICES_DESC")}.
                        </p>
                      </td>
                      <td>
                        <p>{t("PR_FRAUD_PREVENTION")}.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>{t("PR_COMPLIANCE_WITH_LAWS")}.</strong>
                        </p>
                      </td>
                      <td>
                        <p>{t("PR_LAW_COMPLIANCE")}.</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p class="policy_desc mb-1">{t("PR_EU_US_DPF_INFO")}.</p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">4. {t("PR_CHILDRENS_PRIVACY")}</h2>
              <p class="policy_desc mb-1">{t("PR_NO_MINORS")}.</p>
              <p class="policy_desc mb-1">{t("PR_NO_MINOR_DISCLOSURE")}.</p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">5. {t("PR_COOKIES")}</h2>
              <p class="policy_desc mb-1">{t("PR_TRACKING_TECHNOLOGIES")}:</p>
              <ul class="policy__item">
                <li>
                  <a href="https://www.iab.com/" target="_blank">
                    The Interactive Advertising Bureau (US)
                  </a>
                </li>
                <li>
                  <a href="https://iabeurope.eu/" target="_blank">
                    The Interactive Advertising Bureau (EU)
                  </a>
                </li>
                <li>
                  <a href="https://www.youronlinechoices.com/" target="_blank">
                    European Interactive Digital Advertising Alliance (EU)
                  </a>
                </li>
              </ul>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">
                6. {t("PR_THIRD_PARTY_WEBSITES")}
              </h2>
              <p class="policy_desc mb-1">{t("PR_LINKS_TO_THIRD_PARTIES")}.</p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">
                7. {t("PR_DATA_SUBJECT_RIGHTS")}
              </h2>
              <p class="policy_desc mb-1">
                {t("PR_DATA_SUBJECT_RIGHTS_DESC")}:
              </p>
              <ul class="policy__item">
                <li>
                  <b>{t("PR_RIGHT_TO_ACCESS")}.</b>{" "}
                  {t("PR_RIGHT_TO_ACCESS_DESC")}.
                </li>
                <li>
                  <b>{t("PR_RIGHT_TO_DELETE")}.</b>{" "}
                  {t("PR_RIGHT_TO_DELETE_DESC")}.
                </li>
                <li>
                  <b>{t("PR_RIGHT_TO_RECTIFY")}.</b>{" "}
                  {t("PR_RIGHT_TO_RECTIFY_DESC")}.
                </li>
                <li>
                  <b>{t("PR_RIGHT_TO_RESTRICT")}.</b>{" "}
                  {t("PR_RIGHT_TO_RESTRICT_DESC")}.
                </li>
                <li>
                  <b>{t("PR_RIGHT_TO_OBJECT")}.</b>{" "}
                  {t("PR_RIGHT_TO_OBJECT_DESC")}.
                </li>
                <li>
                  <b>{t("PR_RIGHT_TO_PORTABILITY")}.</b>{" "}
                  {t("PR_RIGHT_TO_PORTABILITY_DESC")}.
                </li>
                <li>
                  <b>{t("PR_RIGHT_TO_OPT_OUT_SALE")}.</b>{" "}
                  {t("PR_RIGHT_TO_OPT_OUT_SALE_DESC")}.
                </li>
                <li>
                  <b>{t("PR_RIGHT_TO_OPT_OUT_DECISION_MAKING")}.</b>{" "}
                  {t("PR_RIGHT_TO_OPT_OUT_DECISION_MAKING_DESC")}.
                </li>
              </ul>
              <p class="policy_desc mb-1">{t("PR_SUBMIT_REQUEST")}:</p>
              <ul class="policy__item">
                <li>
                  {t("PR_EMAIL_CAP")}:{" "}
                  <a href="mailto:support@reversly.com">support@reversly.com</a>
                </li>
              </ul>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">
                8. {t("PR_INTERNATIONAL_TRANSFERS")}
              </h2>
              <p class="policy_desc mb-1">{t("PR_DATA_STORAGE")}.</p>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">9. {t("PR_DATA_SECURITY")}</h2>
              <p class="policy_desc mb-1">{t("PR_SECURITY_MEASURES")}.</p>
              <p class="policy_desc mb-1">{t("PR_SECURITY_RISK")}.</p>
            </div>
            <div class="web_docs_section">
              <h2 className="policy_titleh2">10. {t("PR_CONTACT_US")}</h2>
              <p class="policy_desc mb-1">{t("PR_CONTACT_US")}:</p>
              <ul class="policy__item">
                <li>
                  <a href="mailto:support@reversly.com">support@reversly.com</a>
                </li>
              </ul>
            </div>
            <p class="policy_desc mb-1">{t("PP_PRIVACY_POLICY_EDATE")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
