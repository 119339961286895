import React, { useState } from "react";
import { t } from "i18next";
import { infoButtonIcon, nodataIcon } from "../../../assets/images";
import {
  getGraphConfig,
  getRiskLabel,
  renderValue,
  toCamelCase,
} from "../../../utils/commonUtils";
import { informationIcon } from "../../../assets/images";
import PhoneReputationScoreDetails from "../Modals/phoneReputationScoreDetails/phoneReputationScoreDetails";
import GaugeChart from "react-gauge-chart";

const PhoneReputation = ({ reportDetail, number }) => {
  const { phone_reputation_score } = reportDetail?.phoneReport || {};
  const { data, status } = phone_reputation_score || {};
  const [infoModal, setInfoModal] = useState(false);

  const percentage = data?.risk_level >= 0 ? data?.risk_level : 0;
  const { colors, riskTextColor } = getGraphConfig(percentage);
  const getRiskText = () => {
    switch (getRiskLabel(percentage)) {
      case "PR_RISK_LEVEL_LOW":
        return t("PR_PHONE_REPUTATION_SCORE_DESCRIPTION", {
          phone_number: `<p class="mobile_number">${number}</p>`,
        });
      case "PR_RISK_LEVEL_MEDIUM":
        return t("PR_PHONE_REPUTATION_SCORE_DESCRIPTION_MEDIUM", {
          phone_number: `<p class="mobile_number">${number}</p>`,
        });
      case "PR_RISK_LEVEL_HIGH":
        return t("PR_PHONE_REPUTATION_SCORE_DESCRIPTION_LARGE", {
          phone_number: `<p class="mobile_number">${number}</p>`,
        });
      default:
        return;
    }
  };
  return (
    <>
      <div
        className="unlock_premium_sec white-bg-wrap"
        id={toCamelCase("Phone Reputation Score")}
      >
        <div className="ds--unlock__title left-content main_title toggle_area">
          <h2>
            {t("PR_PREMIUM_REPORT_TITLE")}{" "}
            <img
              src={infoButtonIcon}
              alt=""
              onClick={() => setInfoModal(true)}
            />
          </h2>
        </div>
        {status === "found" ? (
          <div className="ds-unlock-content">
            <div className="reputation__risk_score">
              <p className="reputation__description_wrapper">
                {t("PR_RISK_LEVEL")}:
              </p>
              <div className="wpl__reputation_wrapper">
                <GaugeChart
                  className="wpl__gauge_wrapper"
                  id="gauge-chart"
                  nrOfLevels={5}
                  arcsLength={[0.2, 0.2, 0.2, 0.2, 0.2]}
                  colors={colors}
                  percent={percentage / 100}
                  arcPadding={0.03}
                  cornerRadius={7}
                  needleScale={0}
                  needleBaseColor="#fff"
                  hideText={true}
                />
                <div className="wpl__gauge_info">
                  <p
                    className="wpl__risk_Ppercentage"
                    style={{ color: riskTextColor }}
                  >
                    {percentage}%
                  </p>
                  <p
                    className="wpl__risk_leavel"
                    style={{ color: riskTextColor }}
                  >
                    {t(getRiskLabel(percentage))}
                  </p>
                </div>
                <div className="wpl__risk_category">
                  <div className="wpl__risk_info">
                    <div className="wpl__risk_area wpl__category_low">
                      <p className="wpl__lower_area">
                        ▼ <span>{t("PR_LOW")}</span>
                      </p>
                    </div>
                    <div className="wpl__risk_area wpl__category_medium">
                      <p className="wpl__medium_area">
                        ⬤ <span>{t("PR_MEDIUM")}</span>
                      </p>
                    </div>
                    <div className="wpl__risk_area  wpl__category_high">
                      <p className="wpl__higher_area">
                        ▲ <span>{t("PR_HIGH")}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="reputation-phn-content">
              <p
                dangerouslySetInnerHTML={{
                  __html: getRiskText(),
                }}
              ></p>
              <ul className="reputation_listing_area">
                <li>
                  {t("PR_PREMIUM_REPORT_TEXT_1")}
                  <span>{renderValue(data.risk_level)}</span>
                </li>
                <li>
                  {t("PR_PREMIUM_REPORT_TEXT_2")}
                  <span>{renderValue(data.recent_abuse)}</span>
                </li>
                <li>
                  {t("PR_PREMIUM_REPORT_TEXT_3")}
                  <span>{renderValue(data.fraudulent_activity)}</span>
                </li>
                <li>
                  {t("PR_PREMIUM_REPORT_TEXT_4")}
                  <span>{renderValue(data.spam_reported)}</span>
                </li>
                <li>
                  {t("PR_PREMIUM_REPORT_TEXT_5")}
                  <span>{renderValue(data.line_type)}</span>
                </li>
                <li>
                  {t("PR_PREMIUM_REPORT_TEXT_6")}
                  <span>{renderValue(data.prepaid)}</span>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <p className="data_notfound">
            <img src={nodataIcon} alt="" /> {t("PR_RECORD_NOT_FOUND")}
          </p>
        )}
      </div>
      <PhoneReputationScoreDetails
        isOpen={infoModal}
        onClose={() => setInfoModal(false)}
        closable={true}
        className="multiples_popup__wrap_close_button"
      />
    </>
  );
};

export default PhoneReputation;
