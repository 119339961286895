import React, { useEffect, useState } from "react";
import {
  fullUnlockReportImg,
  whiteFullUnlockReportImg,
} from "../../../../assets/images";
import "./unFullLockReport.scss";
import {
  getSectionName,
  getTranslatedSectionName,
  toCamelCase,
  unLockFullReportData,
} from "../../../../utils/commonUtils";
import { t } from "i18next";
import Notify from "../../../../../components/common/Notify/notify";
import Loader from "../../../common/loader/loader";
import { connect } from "react-redux";
import { subscriptionStatus } from "../../../../redux/slice/subscriptionStatusSlice";
import MultiActionModal from "../../Modals/multiActionModal";
import PhoneReportPayModal from "../../Modals/phoneReportPayModal/phoneReportPayModal";
import { useLocation, useNavigate } from "react-router-dom";
import { CONSTANTS } from "../../../../utils/constant";

const UnlockFullReport = ({
  callCheckSubscriptionStatus,
  pricingTitleList,
  getPhoneReportDetails,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const getPhoneReportDetailsData =
    getPhoneReportDetails?.getPhoneReportDetailsData;
  const phoneReportData = getPhoneReportDetails?.getPhoneReportDetailsData?.data?.phoneReport;
  const number = phoneReportData?.phone_number_prefix + phoneReportData?.phone_number;

  const [unlockModal, setUnlockModal] = useState(false);
  const [payModalOpen, setPayModalOpen] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState();
  const [mode, setMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [pricingData, setPricingData] = useState({});
  const [unLockIcon, setUnLockIcon] = useState([]);
  useEffect(() => {
    const unlockSections =
      getPhoneReportDetails?.getPhoneReportDetailsData?.data?.unlocked;
    const array = [];
    if (unlockSections?.length > 0) {
      for (let i = 0; i < unlockSections?.length; i++) {
        array.push(getSectionName(unlockSections[i]));
      }
    }
    setUnLockIcon(["Phone GPS Location", ...array]);
  }, [getPhoneReportDetails?.getPhoneReportDetailsData?.data]);

  useEffect(() => {
    if (pricingTitleList) {
      setPricingData({
        label_1: pricingTitleList?.sections?.["unlock_all_section"]?.amount || 9.95,
        label_2: pricingTitleList?.subscriptions?.[CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT]?.amount || CONSTANTS.WRONG_PRICING.PREMIUM_REPORT,
        symbol: pricingTitleList?.subscriptions?.[CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT]?.symbol || "$",
      });
    }
  }, [pricingTitleList]);

  const handleOpenPayModal = async (mode, data) => {
    setIsLoading(true);
    try {
      const res = await callCheckSubscriptionStatus(CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT);
      const { subscription, usages, canceled } = res.payload?.data || {};

      if (res.type === "subscriptionStatus/fulfilled") {
        if (!subscription && canceled) {
          setActionModal(true);
          setModalType("renew_subscription");
        } else if (!subscription) {
          setSubscriptionData(data);
          setPayModalOpen(true);
          setMode(mode);
        } else if (subscription && usages) {
          setActionModal(true);
          setModalType("open_report");
        } else if (subscription && !usages) {
          setActionModal(true);
          setModalType("more_report_subscription");
        }
      } else {
        throw new Error(res.error.message);
      }
    } catch (error) {
      Notify("error", error.message, "");
    } finally {
      setIsLoading(false);
    }
  };

  const handleReportBoxClick = (sectionName) => {
    setSubscriptionData();
    setSectionName(sectionName);
    setMode("section");
    setUnlockModal(true);
    setPricingData({
      label_1: pricingTitleList?.sections?.[sectionName]?.amount || 3.95,
      label_2: pricingTitleList?.sections?.["unlock_all_section"]?.amount || 9.95,
      symbol: pricingTitleList?.sections?.["unlock_all_section"]?.symbol || "$",
    });
  };

  const handleUnlockButtonClick = () => {
    handleOpenPayModal("full_report", {
      title: t("PR_STEP_3_TITLE_1"),
      description: "PR_UNLOCK_POPUP_DESCRIPTION",
      label1: "PR_UNLOCK_POPUP_TEXT_1",
      label2: "PR_UNLOCK_POPUP_TEXT_2",
      reports: "PR_UNLOCK_POPUP_REPORTS",
    });
    setPricingData({
      label_1: pricingTitleList.sections?.["unlock_all_section"]?.amount || 9.95,
      label_2: pricingTitleList.subscriptions?.[CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT]?.amount || CONSTANTS.WRONG_PRICING.PREMIUM_REPORT,
      symbol: pricingTitleList.subscriptions?.[CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT]?.symbol || "$",
    });
  };
  const navigateOverViewMenu = (sectionName) => {
    const camelCaseSectionName = toCamelCase(sectionName);
    const section = document.getElementById(camelCaseSectionName);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    let queryString = location.search;
    const newUrl = location.pathname + (queryString ? queryString : "");
    navigate(`${newUrl}`);
  };
  return (
    <>
      {isLoading && <Loader />}
      <MultiActionModal
        actionModal={actionModal}
        setActionModal={setActionModal}
        modalType={modalType}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        subscription={CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT}
      />
      <PhoneReportPayModal
        isOpen={unlockModal || payModalOpen}
        onClose={() => {
          setUnlockModal(false);
          setPayModalOpen(false);
        }}
        subscriptionData={subscriptionData}
        sectionName={sectionName}
        mode={mode}
        title={getTranslatedSectionName(sectionName)}
        pricingData={pricingData}
        number={number}
      />
      <div className="unlock-report white-bg-wrap" id="unlockFullReport">
        <div className="main_title">
          <h2>{t("PR_STEP_3_TITLE_1")}</h2>
          <p>{t("PR_UNLOCK_FULL_REPORT_DESCRIPTION")}</p>
        </div>
        <div className="report-box-info">
          {unLockFullReportData.map((item, index) => (
            <div
              key={index}
              className="report-box"
              onClick={() => {
                unLockIcon.includes(item.label)
                  ? navigateOverViewMenu(item.label)
                  : handleReportBoxClick(item.sectionName);
              }}
            >
              {!unLockIcon.includes(item.label) && (
                <img src={whiteFullUnlockReportImg} alt="" />
              )}
              <span>{t(item.translateLabel)}</span>
            </div>
          ))}
        </div>
        <button className="unlock-btn-info" onClick={handleUnlockButtonClick}>
          <span>{t("PR_STEP_3_TITLE_1")}</span>
          <div className="unlock-btn-icon">
            <img src={fullUnlockReportImg} alt="Group" />
          </div>
        </button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  subscriptionStatus: state.subscriptionStatus,
  pricingTitleList: state.pricingTitleList?.getPhoneReportDetailsData?.data,
  getPhoneReportDetails: state.getPhoneReportDetails,
});

const mapDispatchToProps = (dispatch) => ({
  callCheckSubscriptionStatus: (data) => dispatch(subscriptionStatus(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnlockFullReport);
