import { toCamelCase } from "../../../utils/commonUtils";
import { t } from "i18next";
import { dsMonitorIcon } from "../../../assets/images";
import MonitorSwitch from "../noInfoFound/monitorSwitch";

const Monitoring = () => {
  return (
    <>
      <div
        className="ds--monitor-report white-bg-wrap"
        id={toCamelCase("monitoring")}
      >
        <div className="monitor-cmp-wrap">
          <img src={dsMonitorIcon} alt="" />
          <h2>{t("PR_MONITOR_SECTION_TITLE")}</h2>
          <p>{t("PR_MONITOR_SECTION_DESCRIPTION")}</p>
          <MonitorSwitch from={"section"} />
        </div>
      </div>
    </>
  );
};

export default Monitoring;
