import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
export const NotificationList = createAsyncThunk(
  "NotificationList",
  async (page = 1) => {
    try {
      const response = await AxiosInstance.get(
        `/notification/list?page=${page}&limit=10`
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const notificationListSlice = createSlice({
  name: "NotificationList",
  initialState: {
    isLoading: false,
    notificationList: {
      data: {
        notifications: []
      },
      pagination: {
        currentPage: 1,
        totalPages: 0,
        limit: 10,
        totalRecords: 0
      }
    },
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [NotificationList.pending]: (state) => {
      state.isLoading = true;
    },
    [NotificationList.fulfilled]: (state, action) => {
      state.isLoading = false;

      // Update pagination details
      const {pagination, notifications} = action.payload.data;

      if (action.meta.arg > 1) {
        // Append data for subsequent pages
        state.notificationList.data.notifications = [
          ...state.notificationList.data.notifications,
          ...notifications
        ];
      } else {
        // Replace data for the first page
        state.notificationList.data.notifications = notifications;
      }
      state.notificationList.pagination = pagination;

      state.isError = false;
      state.errorMessage = "";
    },
    [NotificationList.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default notificationListSlice.reducer;
