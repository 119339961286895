import React from "react";
import { t } from "i18next";
import {
  copyPastIcon,
  phoneTypeIcon,
  otherPhoneNumbersImg,
  nodataIcon,
} from "../../../assets/images";
import UnlockReport from "../unlockReport/unlockReport";
import { renderValue, toCamelCase } from "../../../utils/commonUtils";
import SectionAction from "../../partials/sectionAction/sectionAction";
import NoInfoFound from "../noInfoFound/noInfoFound";
import { Tooltip } from "antd";

const OtherPhoneNumber = ({ isLocked, sectionName, reportDetail }) => {
  const { other_phone_numbers } = reportDetail?.phoneReport || {};
  const { data, status } = other_phone_numbers || {};
  const { phone_owner_info } = reportDetail?.phoneReport || {};
  const isData = phone_owner_info?.status === "found";

  return isLocked ? (
    <UnlockReport
      title={t("PR_REPORT_POPUP_TEXT_6")}
      image={otherPhoneNumbersImg}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_OTHER_NUMBER_MESSAGE")}
      sectionName={sectionName}
      pricingSectionName={"other_phone_numbers"}
      id={"Other Phone Numbers"}
    />
  ) : !isLocked && status === "not_found" ? (
    <NoInfoFound
      sectionName={sectionName}
      id={toCamelCase("Other Phone Numbers")}
    />
  ) : (
    <div
      className="unlock_phone_type white-bg-wrap"
      id={toCamelCase("Other Phone Numbers")}
    >
      <div className="ds--unlock__title left-content main_title toggle_area">
        <h2>{t("PR_REPORT_POPUP_TEXT_6")}</h2>
        <SectionAction />
      </div>
      {status === "found" && data.length > 0 ? (
        <>
          <div className="unlock_title unlock-description">
            <p>
              {phone_owner_info?.data?.name
                ? t("PR_OTHER_MESSAGE", {
                    phone_owner_full_name: isData
                      ? renderValue(phone_owner_info?.data?.name)
                      : "USER",
                  })
                : t("PR_OTHER_MESSAGE_DESCRIPTION", {
                    phone_number: isData
                      ? reportDetail?.phoneReport?.phone_number_prefix +
                        reportDetail?.phoneReport?.phone_number
                      : "USER",
                  })}
            </p>
          </div>
          {data?.map((item, index) => {
            return (
              <div className="ds--secondary-head" key={index}>
                <div className="ds--phone-image">
                  <img src={phoneTypeIcon} alt="" />
                </div>
                <div class="ds--phone-copy">
                  <Tooltip
                    title={t("PR_COPIED")}
                    trigger="click"
                    onOpenChange={(visible) => {
                      if (visible) {
                        setTimeout(() => {
                          const tooltips =
                            document.querySelectorAll(".ant-tooltip");
                          tooltips.forEach((tooltip) => {
                            tooltip.style.visibility = "hidden"; // Temporarily hide tooltips
                          });
                        }, 2000); // Hide tooltip after 2 seconds
                        setTimeout(() => {
                          const tooltips =
                            document.querySelectorAll(".ant-tooltip");
                          tooltips.forEach((tooltip) => {
                            tooltip.style.visibility = "visible"; // Ensure tooltip visibility on next click
                          });
                        }, 2005);
                      }
                    }}
                  >
                    <p
                      onClick={() =>
                        navigator.clipboard.writeText(item.phone_number)
                      }
                    >
                      <img src={copyPastIcon} alt="" />
                    </p>
                  </Tooltip>
                </div>
                <div className="ds--phone-wrap">
                  <h3>
                    <a href={`tel:${item.phone_number}`}>{item.phone_number}</a>
                  </h3>
                  <div className="ds--phone-content">
                    <p>{t("PR_STEP_3_PHONE")}</p>
                    <span>{renderValue(item.type)}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <p className="data_notfound">
          <img src={nodataIcon} alt="No Data Icon" /> {t("PR_RECORD_NOT_FOUND")}
        </p>
      )}
    </div>
  );
};

export default OtherPhoneNumber;
