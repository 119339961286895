import { t } from "i18next";
import ModalComponent from "../../../components/common/Modal/modal";
import "./subscriptionInfoModal.scss";

const SubscriptionInfoModal = ({ visible, onClose }) => {
  const PR_YOUR_SUBSCRIPTION_STATUS = "Your Subscription Status";
  const PR_SUBSCRIPTION_INFO_DESC =
    "Your subscriptions to {{websiteName}} have been canceled, and no further charges will be made to your credit card. You can still access your account with limited functionalities at no cost";
  const PR_CONTINUE_TO_ACCOUNT = "Continue to Account";
  return (
    <ModalComponent isOpen={visible} onClose={onClose} closable={true} className="subscription__modal__wrap">
      <div className="wpb_report__wrapper">
        <div className="vc_content__general">
          <h2>{t("PR_SUBSCRIPTION_STATUS")}</h2>
          <p>
            {t(PR_SUBSCRIPTION_INFO_DESC, {
              websiteName: "Reversly"
            })}
          </p>
        </div>
        <div class="vc_button__general"><button onClick={onClose} className="vc_btn3-inline">{t(PR_CONTINUE_TO_ACCOUNT)}</button></div>
      </div>
    </ModalComponent>
  );
};

export default SubscriptionInfoModal;
