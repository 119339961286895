import React from "react";
import "./MonitoringReportModal.scss";
import ModalComponent from "../../../common/Modal/modal";
import { monitorReportIcons } from "../../../../assets/images";
import { t } from "i18next";

const MonitoringReportModal = (props) => {
  const { isOpen, onChangeMonitor, setMonitorModal } = props;
  return (
    <>
      <ModalComponent isOpen={isOpen} onClose={() => setMonitorModal(false)} className="monitoring__modal_wrapper">
        <div className="monitoring__report_modal">
          <div className="monitoring__report_img">
            <img src={monitorReportIcons} alt="" />
          </div>
          <div className="monitoring__report_info">
            <h2 className="modal__popup_title">{t("PR_MONITOR_REPORT")}</h2>
            <p className="modal__popup_desc">
            {t("PR_MONITORING_FEATURE_DESCRIPTION")}
            </p>
            <p className="modal__popup_desc">
              {t("PR_TURN_OFF_MONITORING")}
            </p>
          </div>
          <div class="report__popup_pay_btn">
            <p>
              <button onClick={onChangeMonitor}>{t("PR_START_MONITORING")}</button>
            </p>
            <p>
              <button
                class="report_bg_color"
                onClick={() => setMonitorModal(false)}
              >
                {t("PR_CANCEL")}
              </button>
            </p>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};
export default MonitoringReportModal;
