import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/common/loader/loader";
import Notify from "../../components/common/Notify/notify";
import DownloadReportPayModal from "../components/partials/Modals/downloadReportPayModal/downloadReportPayModal";
import { getPdfUrl } from "../redux/slice/getPdfUrlSlice";
import { pdfSubscriptionStatus } from "../redux/slice/pdfSubscriptionStatusSlice";
import ReportMultiactionModal from "../components/partials/Modals/downloadReportPayModal/reportMultiactionModal";

export const useDownloadReport = () => {
    const dispatch = useDispatch();
    const pricingTitleList = useSelector(
        (state) => state.pricingTitleList?.getPhoneReportDetailsData?.data
    );
    const [isLoading, setIsLoading] = useState(false);
    const [downloadReportModal, setDownloadReportModal] = useState(false);
    const [pdfModalType, setPdfModalType] = useState(false);
    const [pdfActionModal, setPdfActionModal] = useState(false);
    const [downloadPricingData, setDownloadPricingData] = useState({});

    useEffect(() => {
        if (pricingTitleList) {
            setDownloadPricingData({
                label_1: pricingTitleList?.sections?.["download_one_report"]?.amount || 1.95,
                label_2: pricingTitleList?.subscriptions?.["pdf_download"]?.amount || 4.95,
                symbol: pricingTitleList?.sections?.["download_one_report"]?.symbol || "$"
            });
        }
    }, [pricingTitleList]);

    const handleDownloadReport = async (reportId) => {
        setIsLoading(true);
        const response = await dispatch(getPdfUrl(reportId));
        if (response.type === "getPdfUrl/fulfilled") {
            const { url } = response.payload.data || {};
            if (url) {
                window.location.href = url;
            } else {
                const res = await dispatch(pdfSubscriptionStatus(reportId));
                if (res.type === "pdfSubscriptionStatus/fulfilled") {
                    const { subscription, pdf_payment, canceled } = res.payload.data;
                    if (!subscription && canceled) {
                        setPdfModalType("renew_subscription");
                        setPdfActionModal(true);
                    } else if (!subscription && !pdf_payment) {
                        setDownloadReportModal(true);
                    } else if (subscription || pdf_payment) {
                        setPdfModalType("download_pdf");
                        setPdfActionModal(true);
                    }
                } else {
                    Notify("error", res?.error?.message || "Something went wrong", '');
                }
            }
        } else {
            Notify("error", response?.error?.message || "Something went wrong", '');
        }
        setIsLoading(false);
    };

    const PdfActionModal = (
        <>
            {/* {isLoading && <Loader />} */}
            <ReportMultiactionModal
                pdfActionModal={pdfActionModal}
                setPdfActionModal={setPdfActionModal}
                pdfModalType={pdfModalType}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                pricingData={downloadPricingData}
            />
            <DownloadReportPayModal
                isOpen={downloadReportModal}
                onClose={() => setDownloadReportModal(false)}
                pricingData={downloadPricingData}
            />
        </>
    );

    return {
        handleDownloadReport,
        PdfActionModal
    };
};
