import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
export const checkAllSubscriptionCancel = createAsyncThunk(
  "checkAllSubscriptionCancel",
  async () => {
    try {
      const response = await AxiosInstance.get(
        `/subscription/fetch_all_canceled`
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const checkAllSubscriptionCancelSlice = createSlice({
  name: "checkAllSubscriptionCancel",
  initialState: {
    isLoading: false,
    allSubscriptionCancel: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [checkAllSubscriptionCancel.pending]: (state) => {
      state.isLoading = true;
    },
    [checkAllSubscriptionCancel.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allSubscriptionCancel = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [checkAllSubscriptionCancel.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default checkAllSubscriptionCancelSlice.reducer;
