import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  animationBg,
  animationPendingIcon,
  reportBtnIco,
  reportNewPhoneIcon,
  reportPhoneIcon,
  tableEllipse,
} from "../../../assets/images";
import moment from "moment-timezone";
import { formatPhoneNumber } from "../../../utils/commonUtils";
import { t } from "i18next";

const ReportList = ({ item, lang }) => {
  const navigate = useNavigate();
  return (
    <div className="wpb_text_content">
      <div className="wpb_box_wrap">
        <div className="vc_general_icon">
          <img src={reportNewPhoneIcon} alt="" />
        </div>
        <div className="vc_general_tel">
          <p className="mobile_number">
            <a>{item.sender}</a>
            {formatPhoneNumber(item.phone_number, item.phone_number_prefix)}
          </p>
        </div>
        <div className="vc_general_text vc_general_date">
          <strong>{t("PR_SEARCH_DATE")}</strong>
          <span>{moment(item.createdAt).format("DD-MM-YYYY")}</span>
        </div>
        <div className="vc_general_text vc_general_status">
          <strong>{t("PR_STEP_3_STATUS")}</strong>
          <span
            className={`badge status-0 ${item.location_status ? "located" : "pending"
              }`}
          >
            {item.location_status
              ? t("SMS_STATUS_LOCATED")
              : t("SMS_STATUS_PENDING")}...
            <img src={animationPendingIcon} alt="" />
          </span>
        </div>
        <div
          className="vc_general_btn"
          onClick={() =>
            navigate(
              `/${lang}/access-report?D=${item.phone_number_prefix}&n=${item.phone_number}&id=${item._id}`
            )
          }
        >
          <p>
            <Link to="">
              {t("PR_DASHBOARD_BUTTON")}
              <img src={reportBtnIco} alt="" />
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReportList;
