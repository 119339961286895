import React, { useState, useEffect } from "react";
import "./faq.scss";
import plusIcon from "../../assets/home/plus.svg";
import minusIcon from "../../assets/home/minus.svg";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";
import useScrollToTop from "../customHook/useScrollToTop";
import { Collapse } from "antd";
import {
  accordionCollapseIcon,
  accordionExpandIcon,
} from "../../phoneReport/assets/images";

const FAQComponent = (props) => {
  const { productList } = props;
  const [collapseTab, setCollapseTab] = useState(0);
  const [activeKey, setActiveKey] = useState(null);
  const { t } = useTranslation();
  const cookie = new Cookies();
  const cur = cookie.get("currency");
  useScrollToTop();

  const trialProduct = productList?.data.find((item) => item.title === "trial");
  const TrialCurr = trialProduct?.currency_options.find(
    (item) => item.parameter === cur
  );

  const subscriptionProduct = productList?.data.find(
    (item) => item.title === "standard"
  );
  const subscriptionProductPrice = subscriptionProduct?.currency_options.find(
    (item) => item.parameter === cur
  );

  const handleCollapseChange = (key) => {
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };
  return (
    <div className="faq-main-section">
      <div className="back-grad-title">
        <div className="pricing-main-title">Public FAQ</div>
      </div>
      <div class="pricing__faws_wrapper">
        <div class="container">
          <div class="faqs__accordion_wrapper">
            <div class="inner__collapse_accordion">
              <div class="wpb__accordion_warp">
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "1" ? ["1"] : []}
                    onChange={() => handleCollapseChange("1")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionCollapseIcon : accordionExpandIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("1")}
                      />
                    )}
                  >
                    <Collapse.Panel header={t("PR_KNOW_TITLE_1")} key="1">
                      <p>{t("PR_KNOW_DESCRIPTION_1")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "2" ? ["2"] : []}
                    onChange={() => handleCollapseChange("2")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionCollapseIcon : accordionExpandIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("2")}
                      />
                    )}
                  >
                    <Collapse.Panel header={t("PR_KNOW_TITLE_2")} key="2">
                      <p>{t("PR_KNOW_DESCRIPTION_2")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "3" ? ["3"] : []}
                    onChange={() => handleCollapseChange("3")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionCollapseIcon : accordionExpandIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("3")}
                      />
                    )}
                  >
                    <Collapse.Panel header={t("PR_KNOW_TITLE_3")} key="3">
                      <p>{t("PR_KNOW_DESCRIPTION_3")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "4" ? ["4"] : []}
                    onChange={() => handleCollapseChange("4")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionCollapseIcon : accordionExpandIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("4")}
                      />
                    )}
                  >
                    <Collapse.Panel header={t("PR_KNOW_TITLE_4")} key="4">
                      <p>{t("PR_KNOW_DESCRIPTION_4")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "5" ? ["5"] : []}
                    onChange={() => handleCollapseChange("5")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionCollapseIcon : accordionExpandIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("5")}
                      />
                    )}
                  >
                    <Collapse.Panel header={t("PR_KNOW_TITLE_5")} key="5">
                      <p>{t("PR_KNOW_DESCRIPTION_5")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "6" ? ["6"] : []}
                    onChange={() => handleCollapseChange("6")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionCollapseIcon : accordionExpandIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("6")}
                      />
                    )}
                  >
                    <Collapse.Panel header={t("PR_FAQ_ABOUT_TITLE_6")} key="6">
                      <p>{t("PR_FAQ_ABOUT_DESCRIPTION_6")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "7" ? ["7"] : []}
                    onChange={() => handleCollapseChange("7")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionCollapseIcon : accordionExpandIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("7")}
                      />
                    )}
                  >
                    <Collapse.Panel header={t("PR_KNOW_TITLE_7")} key="7">
                      <p>{t("PR_FAQ_PRIVACY_DESCRIPTION_5")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "20" ? ["20"] : []}
                    onChange={() => handleCollapseChange("20")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionCollapseIcon : accordionExpandIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("20")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_ACCOUNT_TITLE_1")}
                      key="20"
                    >
                      <p>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_1")}</p>
                      <div>
                        <ul className="faqs__list_content">
                          <li>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_2")}</li>
                          <li>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_3")}</li>
                          <li>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_4")}</li>
                        </ul>
                      </div>
                      <p>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_5")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div className="faqs__collapse_accordion">
                  <Collapse
                    activeKey={activeKey === "25" ? ["25"] : []}
                    onChange={() => handleCollapseChange("25")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionCollapseIcon : accordionExpandIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("25")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_PRIVACY_TITLE_3")}
                      key="25"
                    >
                      <p>
                        {t("PR_FAQ_PRIVACY_DESCRIPTION_3")}{" "}
                        <a href="https://www.reversly.com/en">Reversly.com</a>.
                      </p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQComponent;
